import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Stack, Tab, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import PageContainer from "components/PageContainer";
import { AccountSecurity, AccountSecurityPassword } from "generated/openapi";
import { AccountProfile } from "generated/openapi/models/AccountProfile";
import { useBackendService } from "providers/BackendService";
import { createContext, useContext, useEffect, useState } from "react";
import { asyncNoop } from "utils/utils";
import AccessHistoryTabPanelContent from "./access-history/AccessHistoryTabPanelContent";
import AccountProfileTabContent from "./profile/AccounrProfileTabContent";
import AccountSecurityTabContent from "./security";
import PwdCard from "./security/PwdCard";
import TwoFactorAuthenticationCard from "./security/TwoFactorAuthenticationCard";

type TabValue = "account-profile" | "account-security" | "access-history"

type TabItem = {
    value: TabValue;
    label: string;
}

const tabItems: TabItem[] = [
    {
        value: "account-profile",
        label: "Account Profile",
    },
    {
        value: "account-security",
        label: "Account Security",
    },
    {
        value: "access-history",
        label: "Access History",
    }
];

type AccountProfileContextValue = {
    accountProfile: AccountProfile;
    refetch: () => Promise<void>;
}

const AccountProfileContext = createContext<AccountProfileContextValue | null>(null);

export const useAccountProfile = () => {
    const context = useContext(AccountProfileContext);
    if (!context) {
        throw new Error("You should wrap your component inside <AccountPage> before calling useAccountProfile()");
    }

    return context;
}

type AccountSecurityContextValue = {
    accountSecurity: AccountSecurity;
    refetch: () => Promise<void>;
};

const AccountSecurityContext = createContext<AccountSecurityContextValue | null>(null);

export const useAccountSecurity = () => {
    const context = useContext(AccountSecurityContext);
    if (!context) {
        throw new Error("You should wrap your component inside <AccountPage> before calling useAccountSecurity()");
    }

    return context;
}

export const AccountPage = () => {

    const backendService = useBackendService();

    const [tab, setTab] = useState<TabValue>("account-profile");

    const [accountSecurityContext, setAccountSecurityContext] = useState<AccountSecurityContextValue>({
        accountSecurity: {},
        refetch: asyncNoop,
    });

    const [accountProfileContext, setAccountProfileContext] = useState<AccountProfileContextValue>({
        accountProfile: {},
        refetch: asyncNoop,
    })

    useEffect(() => {
        const initAccountSecurityContext = async () => {
            const response = await backendService.getAccountSecurity();
            setAccountSecurityContext({
                accountSecurity: response,
                refetch: initAccountSecurityContext,
            });
        }

        const initAccountProfileContext = async () => {
            const response = await backendService.getAccountProfile();
            setAccountProfileContext({
                accountProfile: response,
                refetch: initAccountProfileContext,
            });
        }

        initAccountSecurityContext();
        initAccountProfileContext();
    }, [backendService]);

    return (
        <PageContainer variant="outlined">
            <AccountProfileContext.Provider value={accountProfileContext}>
                <AccountSecurityContext.Provider value={accountSecurityContext}>
                    <TabContext value={tab}>
                        <Grid2 container sx={{ height: "100%" }}>
                            <Grid2>
                                <TabList
                                    orientation="vertical"
                                    onChange={(event, value) => setTab(value)}
                                >
                                    {tabItems.map((item) => (
                                        <Tab
                                            key={item.value}
                                            value={item.value}
                                            label={item.label}
                                        />
                                    ))}
                                </TabList>
                            </Grid2>
                            <Grid2 xs>
                                <TabPanel value="account-profile">
                                    <Typography variant="h6">
                                        {"Account Profile"}
                                    </Typography>
                                    <Divider />
                                    <Box py={2}>
                                        <AccountProfileTabContent />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="account-security">
                                    <Typography variant="h6">
                                        {"Account Security"}
                                    </Typography>
                                    <Divider />
                                    <Box py={2}>
                                        <AccountSecurityTabContent />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="access-history" sx={{ height: "100%" }}>
                                    <Typography variant="h6">
                                        {"Access History"}
                                    </Typography>
                                    <Box height="100%" py={2}>
                                        <AccessHistoryTabPanelContent />
                                    </Box>
                                </TabPanel>
                            </Grid2>
                        </Grid2>
                    </TabContext>
                </AccountSecurityContext.Provider>
            </AccountProfileContext.Provider>
        </PageContainer>
    )
}

export default AccountPage;