import { Box } from "@mui/system";
import { FormField, FormFieldCheckboxGroup, FormFieldMultipleEntry, FormSection } from "components/Form";

export const ClientFormContent = () => {
    return (
        <>
            <FormField
                required
                variant="long"
                fieldName="username"
                label={"Username"}
            />
            <FormField
                required
                variant="long"
                fieldName="clientName"
                label={"Client Name"}
            />
            <FormSection>
                <FormFieldCheckboxGroup
                    variant="fullWidth"
                    fieldName="clientAuthenticationMethods"
                    options={["client_secret_basic", "client_secret_post", "none"]}
                    label={"Client Authentication Method"}
                />
                <FormField
                    variant="short"
                    fieldName="usePKCE"
                    fieldType="checkbox"
                    label={"Use PKCE?"}
                />
            </FormSection>
            <FormSection>
                <Box sx={{ my: 1 }}>
                    <FormFieldCheckboxGroup
                        variant="fullWidth"
                        fieldName="authorizationGrantTypes"
                        options={["authorization_code", "refresh_token", "client_credentials"]}
                        label={"Authorization Grant Type"}
                    />
                </Box>
            </FormSection>
            <FormSection>
                <FormFieldMultipleEntry
                    variant="long"
                    fieldName="redirectUris"
                    label="Redirect URI"
                />
            </FormSection>
            <FormSection>
                <FormFieldMultipleEntry
                    variant="long"
                    fieldName="scopes"
                    label="Scope"
                />
            </FormSection>
        </>
    )
}

export default ClientFormContent;