import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import Form, { FormProps } from "components/Form/Form";
import { nanoid } from "nanoid";
import React, { ComponentProps, PropsWithChildren, useEffect, useMemo, useState } from "react";

export type FormDialogCloseReason = "cancelled" | "backdropClick" | "escapeKeyDown"

export type FormDialogCloseEventHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: FormDialogCloseReason) => void;

type FormDialogProps<T extends object> = FormProps<T> & {
    open: boolean;
    onClose?: FormDialogCloseEventHandler;
    title?: string;
    submitDisabled?: boolean;
    cancelText?: string;
    submitText?: string;
    dialogProps?: Omit<DialogProps, "open" | "onClose" | "TransitionProps">;
}

export const FormDialog = <T extends object>(props: PropsWithChildren<FormDialogProps<T>>) => {

    const {
        open,
        onClose,
        title,
        submitDisabled,
        cancelText,
        submitText,
        dialogProps,
        ...formProps
    } = props;

    const { id, formData, ...otherFormProps } = formProps;

    const formId = useMemo(() => {
        if (id) {
            return id;
        }
        return nanoid();
    }, [id]);

    const [viewFormData, setViewFormData] = useState(formData);

    useEffect(() => {
        if (open) {
            setViewFormData(formData);
        }
    }, [formData, open])

    const handleOnCancel: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        onClose && onClose(event, "cancelled");
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionProps={{
                onExited: () => {
                    // Update view state after exited
                    setViewFormData(formData);
                },
            }}
            {...dialogProps}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Form
                    id={formId}
                    formData={open ? formData : viewFormData}
                    {...otherFormProps}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnCancel}>
                    {cancelText ?? "Cancel"}
                </Button>
                <Button disabled={submitDisabled} type="submit" form={formId}>
                    {submitText ?? "Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

FormDialog.defaultProps = {
    submitDisabled: false,
}