import React from 'react';
import { SupportedLanguage } from 'i18n';
import { MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const options = [
    { value: 'en', label: 'Eng' },
    { value: 'zh-HK', label: '繁' },
    { value: 'zh-CN', label: '简' },
];

type LanguageSelectorProps = Omit<SelectProps<string>, "value" | "onChange" | "defaultValue"> & {

}

export const LanguageSelector = (props: LanguageSelectorProps) => {

    const { i18n } = useTranslation();
    const language = i18n.language as SupportedLanguage;

    const handleLanguageOnChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        const value = event.target.value;
        i18n.changeLanguage(value);
    }

    return (
        <Select
            value={language}
            onChange={handleLanguageOnChange}
            {...props}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    )
}

export default LanguageSelector;
