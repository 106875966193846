import { MoreHoriz, Person } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Card, CardActionArea, CardHeader, IconButton, ListItem, ListItemIcon, Stack, Tab } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DefaultService } from "generated/openapi";
import { UserAccount } from "generated/openapi/models/UserAccount";
import { useBackendService } from "providers/BackendService";
import { useEffect, useState } from "react";
import { DataSource } from "types/DataSource";
import { AsReturnType } from "utils/types";
import { objectUpdater } from "utils/utils";
import PendingUsersTabContent from "./PendingUsersTabContent";
import UsersTabContent from "./UsersTabContent";

type TabValue = "users" | "pending-users"

type TabItem = {
    value: TabValue;
    label: string;
}

const tabItems: TabItem[] = [
    {
        value: "users",
        label: "Users",
    },
    {
        value: "pending-users",
        label: "Pending Users",
    }
];

type CachedData = {
    users?: DataSource<UserAccount>;
    pendingUsers?: DataSource<UserAccount>;
}

export const UserConsole = () => {

    const backendService = useBackendService();

    const [tab, setTab] = useState<TabValue>("users");

    const [cachedData, setCachedData] = useState<CachedData>({});

    useEffect(() => {
        const fetchData = async () => {
            if (tab === "users") {
                const response = await backendService.getCompanyUsers({});
                setCachedData(objectUpdater("users", response));
            } else if (tab === "pending-users") {
                const response = await backendService.getCompanyPendingUsers({});
                setCachedData(objectUpdater("pendingUsers", response));
            }
        }
        fetchData();
    }, [backendService, tab]);

    const fetchUserData: AsReturnType<typeof backendService.getCompanyUsers, Promise<void>> = async (filter) => {
        const response = await backendService.getCompanyUsers(filter);
        setCachedData(objectUpdater("users", response));
    }

    const fetchPendingUserData: AsReturnType<typeof backendService.getCompanyPendingUsers, Promise<void>> = async (filter) => {
        const response = await backendService.getCompanyPendingUsers(filter);
        setCachedData(objectUpdater("pendingUsers", response));
    }

    return (
        <TabContext value={tab}>
            <Stack>
                <TabList onChange={(event, value) => setTab(value)}>
                    {tabItems.map((item) => (
                        <Tab
                            key={item.value}
                            value={item.value}
                            label={item.label}
                        />
                    ))}
                </TabList>
                <TabPanel value="users">
                    <UsersTabContent
                        data={cachedData.users?.data ?? []}
                        onFilterChange={fetchUserData}
                    />
                </TabPanel>
                <TabPanel value="pending-users">
                    <PendingUsersTabContent
                        data={cachedData.pendingUsers?.data ?? []}
                        onFilterChange={fetchPendingUserData}
                    />
                </TabPanel>
            </Stack>
        </TabContext>
    )
}

export default UserConsole;