import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Params = {
    error: string;
    errorDescription: string;
    errorUri: string;
}

export const AuthorizeErrorPage = () => {
    const { t } = useTranslation('translation', {keyPrefix: "translation"});

    const [searchParams] = useSearchParams();

    const [params, setParams] = useState<Params>({
        error: "",
        errorDescription: "",
        errorUri: "",
    });

    useEffect(() => {
        const error = searchParams.get("error") ?? "";
        const errorDescription = searchParams.get("error_description") ?? "";
        const errorUri = searchParams.get("error_uri") ?? "";
        setParams({
            error,
            errorDescription,
            errorUri,
        });
    }, [searchParams]);



    return (
        <Dialog
            open
            hideBackdrop
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                {t("error_msg.authorization_error")}
            </DialogTitle>
            <DialogContent>
                <Typography
                    gutterBottom
                    variant="h5"
                >
                    {t("error_msg.error") + ":"}{params.error}
                </Typography>
                <Typography
                    paragraph
                    variant="body1"
                >
                    {params.errorDescription}
                </Typography>
                <DialogContentText variant="body2">
                    {t("error_msg.cannot_authorize")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    color="inherit"
                    variant="contained"
                    onClick={() => location.href = process.env.PUBLIC_URL + "/v2/logout"}
                >
                    {t("error_msg.try_with_other")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AuthorizeErrorPage;