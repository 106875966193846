import { Lock, MoreHoriz, Paid, Person } from "@mui/icons-material";
import { Badge, Box, Button, Card, CardActionArea, CardHeader, IconButton, Menu, MenuItem, PopoverPosition, Toolbar, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"
import { FormField } from "components/Form";
import { FormDialog } from "components/FormDialog/FormDialog";
import { UserAccount, UserStatus } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import React, { useEffect, useState } from "react";
import { withToast } from "utils/withToast";
import { AsPromise } from "utils/types";
import { joinText } from "utils/utils";

type UsersTabContentProps = {
    data: UserAccount[];
    onFilterChange: (filter: Filter) => void;
}

type Filter = {
    username?: string,
    fullName?: string,
    roles?: Array<string>,
    billing?: boolean,
    statuses?: Array<UserStatus>,
}

type AccountIconProps = {
    billing: boolean;
    status: UserStatus;
}

const AccountIcon = (props: AccountIconProps) => {

    const { billing, status } = props;

    const renderBadgeContent = () => {

        const color = status === "enabled" ? "inherit" : "disabled";

        if (status === "locked") {
            return <Lock fontSize="small" color={color} />
        }

        if (billing) {
            return <Paid fontSize="small" color={color} />
        }
    }

    return (
        <Badge
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            badgeContent={renderBadgeContent()}
        >
            <Person color={status === "enabled" ? "action" : "disabled"} />
        </Badge>
    )
}

type FilterDialogProps = {
    open: boolean;
    onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown" | "cancelled") => void;
    filter: Filter;
    onFilterSubmit: (event: React.FormEvent<Element>, filter: Filter) => void;
}

const FilterDialog = (props: FilterDialogProps) => {

    const [filterView, setFilterView] = useState<Filter>({
        username: "",
        fullName: "",
        roles: [],
        statuses: [],
    });

    useEffect(() => {
        const {
            username = "",
            fullName = "",
            roles = [],
            statuses = [],
        } = props.open ? props.filter : {};
        setFilterView({
            username,
            fullName,
            roles,
            statuses,
        });
    }, [props.filter, props.open]);

    const handleOnSubmit: React.FormEventHandler = (event) => {
        event.preventDefault();
        props.onFilterSubmit(event, filterView);
        return false;
    }

    return (
        <FormDialog
            title={"Search"}
            open={props.open}
            onClose={props.onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs",
            }}
            formData={filterView}
            onFormDataChange={(event, formData) => setFilterView(formData)}
            onSubmit={handleOnSubmit}
        >
            <FormField
                variant="long"
                label={"Name"}
                fieldName="fullName"
            />
            <FormField
                variant="long"
                label={"Email"}
                fieldName="username"
            />

        </FormDialog>
    )
}

export const UserTabContent = (props: UsersTabContentProps) => {

    const backendService = useBackendService();

    const { data, onFilterChange } = props;

    const [openedDialog, setOpenedDialog] = useState<"" | "filter-dialog">("");

    const [filter, setFilter] = useState<Filter>({});

    const handleFilterOnSubmit = (event: React.FormEvent, filter: Filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
        setOpenedDialog("");
    }

    const renderFilterText = () => {
        const {
            fullName,
            username,
            roles,
            statuses,
        } = filter;

        const filters = [];
        if (fullName) {
            filters.push(`name contains "${fullName}"`);
        }
        if (username) {
            filters.push(`user email contains "${username}"`);
        }
        if (roles && roles.length > 0) {
            filters.push(`role is ${joinText(roles, "or")}`);
        }
        if (statuses && statuses.length > 0) {
            filters.push(`statuses is ${joinText(statuses, "or")}`);
        }

        if (filters.length > 0) {
            return (
                <Typography variant="subtitle2">
                    {"Search by: "}{filters.join("; ")}
                </Typography>
            )
        }
    }

    const [selectedUser, setSelectedUser] = useState<UserAccount>();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const createMenuButtonOnClickHandler = (userAccount: UserAccount): React.MouseEventHandler<HTMLElement> => (event) => {
        event.stopPropagation();
        setSelectedUser(userAccount);
        setAnchorEl(event.target as HTMLElement);
    }

    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>();
    const createOnContextMenuHandler = (userAccount: UserAccount): React.MouseEventHandler => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedUser(userAccount);
        setAnchorPosition({
            left: event.clientX,
            top: event.clientY,
        });
    }

    const closeMenu = () => {
        setSelectedUser(undefined);
        setAnchorPosition(undefined);
        setAnchorEl(undefined);
    }

    const reload = () => onFilterChange(filter);

    const renderMenu = () => {
        if (selectedUser) {
            const {
                username,
                status,
                billing,
            } = selectedUser;

            const createActionHandler = (callback: AsPromise<React.MouseEventHandler>): React.MouseEventHandler => async (event) => {
                closeMenu();
                await callback(event);
                reload();
            }

            const handleDeactivateOnClick = async () => {
                await withToast({
                    success: "Deactivated user",
                    error: true
                }, async () => {
                    await backendService.postCompanyUsersDeactivate({
                        requestBody: username
                    });
                });
            }

            const handleActivateOnClick = async () => {
                await withToast({
                    success: "Activated user",
                    error: true
                }, async () => {
                    await backendService.postCompanyUsersActivate({
                        requestBody: username
                    });
                });
            }

            const handleUnlockOnClick = async () => {
                await withToast({
                    success: "Unlocked user",
                    error: true
                }, async () => {
                    await backendService.postCompanyUsersUnlock({
                        requestBody: username
                    });
                });
            }

            const handleBillingOnClick = async () => {
                await withToast({
                    success: "Marked as billing user",
                    error: true
                }, async () => {
                    await backendService.postCompanyUsersBilling({
                        requestBody: username
                    });
                });
            }

            return (
                <Menu
                    open={!!selectedUser}
                    anchorEl={anchorEl}
                    anchorPosition={anchorPosition}
                    anchorReference={anchorPosition ? "anchorPosition" : "anchorEl"}
                    onClose={() => closeMenu()}
                >                    {status === "enabled" && (
                    <MenuItem onClick={createActionHandler(handleDeactivateOnClick)}>
                        {"Deactivate"}
                    </MenuItem>
                )}
                    {status === "disabled" && (
                        <MenuItem onClick={createActionHandler(handleActivateOnClick)}>
                            {"Activate"}
                        </MenuItem>
                    )}
                    {status === "locked" && (
                        <MenuItem onClick={createActionHandler(handleUnlockOnClick)}>
                            {"Unlock"}
                        </MenuItem>
                    )}
                    {billing === false && (
                        <MenuItem onClick={createActionHandler(handleBillingOnClick)}>
                            {"Mark as billing user"}
                        </MenuItem>
                    )}
                </Menu>
            )
        }

        return null;
    }

    return (
        <>
            <Toolbar disableGutters>
                <Box flexGrow={1}>
                    <Typography variant="h6">
                        {"Users"}
                    </Typography>
                    {renderFilterText()}
                </Box>
                <Button onClick={() => setOpenedDialog("filter-dialog")}>
                    {"Search"}
                </Button>
            </Toolbar>
            <Grid2 container spacing={1}>
                {data.map((userAccount) => (
                    <Grid2 key={userAccount.username} md={6} xs={12}>
                        <Card variant="outlined">
                            <CardActionArea onContextMenu={createOnContextMenuHandler(userAccount)} component="div">
                                <CardHeader
                                    avatar={<AccountIcon billing={userAccount.billing || false} status={userAccount.status!} />}
                                    title={userAccount.fullName}
                                    subheader={userAccount.username}
                                    action={(
                                        <IconButton onClick={createMenuButtonOnClickHandler(userAccount)}>
                                            <MoreHoriz />
                                        </IconButton>
                                    )}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                ))}
            </Grid2>
            <FilterDialog
                open={openedDialog === "filter-dialog"}
                onClose={() => setOpenedDialog("")}
                filter={filter}
                onFilterSubmit={handleFilterOnSubmit}
            />
            {renderMenu()}
        </>
    )
}

export default UserTabContent;