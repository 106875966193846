import { ExitToApp, Groups, Monitor, Security, SupervisorAccount } from "@mui/icons-material";
import { Card, CardActionArea, CardHeader, Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import HasRole from "components/HasRole";
import HasApp from "components/HasApp";
import { COMPANY_ADMIN_AUTHORITIES, SYS_ADMIN_AUTHORITIES } from "constants/authorities";
import { useNavigate } from "react-router-dom";
import publicConfigApi from "store/apis/publicConfig";
import { useEffect } from "react";

const a = 1;

export const HomePage = () => {

    const { data: config } = publicConfigApi.usePublicConfigQuery(undefined);

    const navigate = useNavigate();

    return (
        <Container sx={{ py: 2 }}>
            <Grid2 container spacing={2}>
                {/* <Grid2 sm={6}>
                    <Card variant="outlined" >
                        <CardActionArea onClick={() => navigate("/account")}>
                            <CardHeader
                                avatar={<Security />}
                                title={"My Account"}
                                subheader={"Manage account security"}
                            />
                        </CardActionArea>
                    </Card>
                </Grid2>
                <HasRole roles={COMPANY_ADMIN_AUTHORITIES}>
                    <Grid2 sm={6}>
                        <Card variant="outlined">
                            <CardActionArea onClick={() => navigate("/company/console/user")}>
                                <CardHeader
                                    avatar={<SupervisorAccount />}
                                    title={"Company Users"}
                                    subheader={"Manage company users"}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                </HasRole>
                <HasRole roles={SYS_ADMIN_AUTHORITIES}>
                    <Grid2 sm={6}>
                        <Card variant="outlined">
                            <CardActionArea onClick={() => navigate("/system/console/client")}>
                                <CardHeader
                                    avatar={<Monitor />}
                                    title={"System"}
                                    subheader={"Open System Administration Console"}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                </HasRole> */}
                <Grid2 sm={6}>
                    <Card variant="outlined" >
                        <CardActionArea onClick={() => location.href = config!.portalUrl}>
                            <CardHeader
                                avatar={<ExitToApp />}
                                title={"Translate Platform"}
                                subheader={"Go to Translate platform"}
                            />
                        </CardActionArea>
                    </Card>
                </Grid2>
                <HasApp app="audto">
                    <Grid2 sm={6}>
                        <Card variant="outlined" >
                            <CardActionArea onClick={() => location.href = config!.audtoUrl}>
                                <CardHeader
                                    avatar={<ExitToApp />}
                                    title={"AUDTO"}
                                    subheader={"Go to AUDTO platform"}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                </HasApp>
            </Grid2>
        </Container>
    )
};

export default HomePage;