import axios from "axios";
import { baseApi } from "./baseApi";
import { resolveServerPath } from "utils/utils";

export type Oauth2UserInfoGetQueryReponse = {
    apps: string[];
    roles: string[];
    email: string;
}

const oauth2Api = baseApi.injectEndpoints({
    endpoints: (build) => ({
        oauth2UserInfoGet: build.query<Oauth2UserInfoGetQueryReponse, void>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const { data } = await axios.get(resolveServerPath() + "/userinfo");
                    return { data }
                } catch (error) {
                    return { error: "error" };
                }
            },
        }),
    }),
    overrideExisting: false,
});

export default oauth2Api;