import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const defaultTheme = createTheme({
    components: {
        MuiInputBase: {
            defaultProps: {
                size: "small"
            }
        },
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                body: {
                    backgroundImage: theme.palette.mode === "light" ? "linear-gradient(#126537, #3f87a6)" : undefined,
                }
            })
        }
    },
    palette: {
        // mode: "dark",
        primary: {
            main: '#307f53',
        },
        secondary: {
            main: '#f7811b',
        },
    },
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
