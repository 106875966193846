/** @jsxImportSource @emotion/react */
import React, { createContext, useContext, useMemo, useRef } from "react";

type FormContextType<T = any> = {
    formData: T;
    disabledFields: Array<keyof T>;
    excludedFields: Array<keyof T>;
    onFormDataChange: (event: React.ChangeEvent<unknown>, formData: Partial<T>) => void;
}

const FormContext = createContext<FormContextType | null>(null);

export const useForm = <T extends object>() => useContext(FormContext as React.Context<FormContextType<T> | null>)!;

export type FormProps<T extends object> = {
    formData: T;
    id?: string;
    onFormDataChange: (event: React.ChangeEvent<unknown>, formData: T) => void;
    disabledFields?: Array<keyof T>;
    excludedFields?: Array<keyof T>;
    method?: string;
    action?: string;
    onSubmit?: React.FormEventHandler;
    onReset?: React.FormEventHandler;
    rowGap?: number | string;
    columnGap?: number | string;
}

export const Form = <T extends object>(props: React.PropsWithChildren<FormProps<T>>) => {
    const handleFormDataOnUpdateRef = useRef<((event: React.ChangeEvent<unknown>, formData: Partial<T>) => void)>();
    handleFormDataOnUpdateRef.current = (event, formData) => {
        const newFormData = {
            ...props.formData,
            ...formData,
        }

        props.onFormDataChange(event, newFormData);
    }

    return (
        <FormContext.Provider value={useMemo(() => ({
            formData: props.formData,
            disabledFields: props.disabledFields ?? [],
            excludedFields: props.excludedFields ?? [],
            onFormDataChange: (event, formData) => handleFormDataOnUpdateRef.current!(event, formData),
        }), [props.disabledFields, props.excludedFields, props.formData])}>
            <form
                id={props.id}
                method={props.method}
                action={props.action}
                onSubmit={props.onSubmit}
                onReset={props.onReset}
                css={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "fit-content(30%) 1fr fit-content(30%)",
                    rowGap: props.rowGap,
                    columnGap: props.columnGap,
                    alignItems: "center",
                    "* :where(& > *)": {
                        gridColumn: "span 3",
                    }
                }}
            >
                {props.children}
            </form>
        </FormContext.Provider>
    )
}

Form.defaultProps = {
    rowGap: 12,
    columnGap: 12,
}

export default Form;
