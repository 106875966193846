import './App.css';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import HasRole from './components/HasRole';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BackendService } from 'providers/BackendService';
import { AppBar, Avatar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import ConfirmDialogProvider from 'components/ConfirmDialog/ConfirmDialogProvider';
import { AccountRecoveryPage, AccountPage, ClientConsolePage, ForgetPasswordPage, HomePage, LoginPage, OtpPage, AuthorizeErrorPage } from 'features';
import { CompanyUserConsolePage } from 'features/company';
import { ConfirmRegistrationPage } from 'features/registration';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import CompanyIcon from 'components/CompanyIcon/CompanyIcon'
import RenewPasswordPage from 'features/login/RenewPasswordPage';
import LoadingBackdrop, { LoadingBackdropProivder } from 'components/LoadingBackdrop';
import publicConfigApi from 'store/apis/publicConfig';
import RememberMePage from 'features/login/RememberMePage';

declare global {
    interface Window {
        __RUNTIME_ENV__: {
            REACT_APP_COOKIE_DOMAIN: string;
            REACT_APP_ENV: string;
            REACT_APP_SUPPORT_FORM_LOGIN: string;
            REACT_APP_SUPPORT_SSO_MICROSOFT_LOGIN: string;
            REACT_APP_SSO_MICROSOFT_ENTRY_POINT: string;
            REACT_APP_FAV_ICON_PATH: string;
        };
    }
}

export const App = () => {
    useEffect(() => {
        const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
            return;
        }
        link.href = window.__RUNTIME_ENV__.REACT_APP_FAV_ICON_PATH;
    }, []);

    const renderRoutes = () => {
        return (
            <Routes>
                <Route
                    element={(
                        <BackendService>
                            <Outlet />
                        </BackendService>
                    )}
                >
                    {UnAuthenticatedRoutes()}
                </Route>

                <Route
                    element={(
                        <BackendService>
                            <Outlet />
                        </BackendService>
                    )}
                >
                    {AuthenticatedRoutes()}
                </Route>
            </Routes>
        )
    }

    return (
        <Box sx={{
            height: "100vh",
            width: "100%",
            backgroundImage: "linear-gradient(#126537, #3f87a6)",
        }}>
            <ToastContainer />
            <LoadingBackdropProivder>
                {renderRoutes()}
            </LoadingBackdropProivder>
        </Box >
    );
}

const UnAuthenticatedRoutes = () => {
    return (
        <Route element={<Outlet />}>
            <Route
                path="/login"
                element={<LoginPage />}
            />

            <Route
                path="/otp"
                element={<OtpPage />}
            />

            <Route
                path="/renew-password"
                element={<RenewPasswordPage />}
            />

            <Route
                path="/remember-me"
                element={<RememberMePage />}
            />

            <Route
                path="/forget-password"
                element={<ForgetPasswordPage />}
            />

            <Route
                path="/account-recovery"
                element={<AccountRecoveryPage />}
            />

            <Route
                path="/account-registration/confirm"
                element={<ConfirmRegistrationPage />}
            />

            <Route
                path="/rejected"
                element={<AuthorizeErrorPage />}
            />
        </Route>
    )
}

const AuthenticatedOutlet = () => {

    const { data: config } = publicConfigApi.usePublicConfigQuery(undefined);

    const navigate = useNavigate();

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handlePlatformOnClick = () => {
        if (config) {
            location.href = config.portalUrl;
        }
    }

    const handleLogoutOnClick = () => {
        location.href = process.env.PUBLIC_URL + "/v2/logout";
    }

    return (
        <>
            <Menu
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                anchorEl={menuButtonRef.current}
            >
                {/* <MenuItem onClick={handlePlatformOnClick}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={"Go to DeepTranslate Platform"} />
                </MenuItem> */}
                <MenuItem onClick={handleLogoutOnClick}>
                    <ListItemText inset primary={"Logout"} />
                </MenuItem>
            </Menu>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
            >
                <AppBar position="sticky">
                    <Toolbar>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            onClick={() => navigate("/home")}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <Typography sx={{ height: '50px' }}>
                                <CompanyIcon />
                            </Typography>
                            <Typography variant="h6">
                                {"Account platform"}
                            </Typography>
                        </Stack>
                        <Box flexGrow={1} />
                        <IconButton ref={menuButtonRef} onClick={() => setIsMenuOpen(true)}>
                            <AccountCircle fontSize="large" />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <ConfirmDialogProvider>
                    <Outlet />
                </ConfirmDialogProvider>
            </Box>
        </>
    )
}

const AuthenticatedRoutes = () => {
    return (
        <Route element={<AuthenticatedOutlet />}>
            <Route element={<Outlet />}>
                <Route
                    path="/home"
                    element={<HomePage />}
                />
                <Route
                    path="/account"
                    element={<AccountPage />}
                />
            </Route>

            <Route
                element={(
                    <HasRole roles={["company_admin"]}>
                        <Outlet />
                    </HasRole>
                )}
            >
                <Route
                    path="/company/console/user"
                    element={<CompanyUserConsolePage />}
                />
            </Route>

            <Route
                element={(
                    <HasRole roles={["sys_admin"]}>
                        <Outlet />
                    </HasRole>
                )}
            >
                <Route
                    path="/system/console/client"
                    element={<ClientConsolePage />}
                />
            </Route>

            <Route
                path="*"
                element={<Navigate to="/home" />}
            />
        </Route>
    )
}

export default App;
