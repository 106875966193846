import { Checkbox, FormControlLabel, FormGroup, SxProps, Theme } from "@mui/material";
import React from "react";
import { useForm } from "./Form";
import { FormFieldValue, Option } from "./type";
import FormFieldWrapper from "./FormFieldWrapper";

type FormFieldProps = {
    variant: "fullWidth" | "long",
    label?: React.ReactNode,
    fieldName: string,
    fieldProps?: object,
    options: Option[],
    sx?: SxProps<Theme>;
}

export const FormFieldCheckboxGroup = <T extends { [x: string]: FormFieldValue[] }>(props: FormFieldProps) => {
    const { formData, disabledFields, excludedFields, onFormDataChange } = useForm<T>();

    const { label, fieldName, fieldProps } = props;

    const value = formData[fieldName];
    const disabled = disabledFields.includes(fieldName);
    const excluded = excludedFields.includes(fieldName);

    const updateValue = (event: React.ChangeEvent<HTMLInputElement>, fieldValue: FormFieldValue, checked: boolean) => {
        if (checked) {
            const newValue = [...value, fieldValue];
            onFormDataChange(event, { [event.target.name]: newValue } as Partial<T>);
        } else {
            const newValue = value.filter((value) => value !== fieldValue);
            onFormDataChange(event, { [event.target.name]: newValue } as Partial<T>);
        }
    }

    const renderField = () => {
        return (
            <FormGroup row>
                {props.options.map((option) => {
                    if (typeof option === "object") {
                        return (
                            <FormControlLabel
                                key={option.value}
                                name={fieldName}
                                disabled={disabled}
                                value={option.value}
                                label={option.label}
                                checked={value.includes(option.value)}
                                control={(<Checkbox onChange={(event, checked) => updateValue(event, option.value, checked)} />)}
                                {...option.optionProps}
                                {...fieldProps}
                            />
                        )
                    } else {
                        return (
                            <FormControlLabel
                                name={fieldName}
                                value={option}
                                label={option}
                                disabled={disabled}
                                checked={value.includes(option)}
                                control={(<Checkbox onChange={(event, checked) => updateValue(event, option, checked)} />)}
                                {...fieldProps}
                            />
                        )
                    }
                })}
            </FormGroup>

        )
    }

    if (excluded) {
        return null;
    }

    return (
        <FormFieldWrapper
            variant={props.variant}
            label={props.label}
            field={renderField()}
            sx={props.sx}
        />
    )
}

export default FormFieldCheckboxGroup;