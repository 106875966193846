import { Button, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { PropsWithChildren, useEffect, useMemo, useRef } from "react";

const inheritTheme = createTheme();

const warningTheme = createTheme({
    components: {
        MuiDialogTitle: {
            defaultProps: {
                sx: (theme) => ({
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.warning.contrastText,
                }),
            }
        }
    }
});

const errorTheme = createTheme({
    components: {
        MuiDialogTitle: {
            defaultProps: {
                sx: (theme) => ({
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                }),
            }
        }
    }
});

type ConfirmDialogProps = {
    type?: "warning" | "error" | "inherit" | "primary" | "secondary" | "success" | "info";
    title?: string;
    content?: string;
    open: boolean;
    onClose: (event: {} | React.MouseEvent<HTMLButtonElement>, reason?: "backdropClick" | "escapeKeyDown") => void;
    onConfirm: (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    TransitionProps?: TransitionProps;
    confirmOnEnter?: boolean;
}

export const ConfirmDialog = (props: PropsWithChildren<ConfirmDialogProps>) => {
    const onConfirmRef = useRef(props.onConfirm);
    onConfirmRef.current = props.onConfirm;

    useEffect(() => {
        if (props.open && props.confirmOnEnter) {
            const eventListener = (event: KeyboardEvent) => {
                if (event.key === "Enter") {
                    onConfirmRef.current(event);
                }
            }

            addEventListener('keydown', eventListener);

            return () => {
                removeEventListener('keydown', eventListener);
            }
        }
    }, [props.open, props.confirmOnEnter]);

    const theme = useMemo(() => {
        switch (props.type) {
            case "warning": {
                return warningTheme;
            }

            case "error": {
                return errorTheme;
            }
           
            default: {
                return inheritTheme;
            }
        }
    }, [props.type]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                maxWidth="sm"
                open={props.open}
                onClose={props.onClose}
                TransitionProps={props.TransitionProps}
            >
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {props.content}
                    </DialogContentText>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={props.onClose}>
                        {"Close"}
                    </Button>
                    <Button
                        variant="contained"
                        color={props.type}
                        onClick={props.onConfirm}
                    >
                        {"Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default ConfirmDialog;
