import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react"
import axios, { AxiosRequestConfig } from "axios"
import { resolveServerPath } from "utils/utils";

export type QueryArgs = {
    url: string;
    config?: AxiosRequestConfig;
}

const axiosBaseQuery = (): BaseQueryFn<QueryArgs> => async (args, api, extraOptions) => {
    try {
        const {
            url,
            config = {},
        } = args;

        if (!config.method) {
            config.method = 'GET';
        }

        if (!Object.hasOwn(config, 'withCredentials')) {
            config.withCredentials = true;
        }

        const response = await axios.request({ 
            url: resolveServerPath() + "/api" + url,
            ...config 
        });

        return {
            data: response.data,
        };

    } catch (error) {
        return { error };
    }
}

export const baseApi = createApi({
    baseQuery: axiosBaseQuery(),
    endpoints: () => ({}),
});

export default baseApi;