import {  AuthService, DefaultService, OpenAPI } from "generated/openapi";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { resolveServerPath } from "utils/utils";

const BackendServiceContext = createContext<DefaultService | null>(null);

export const BackendService = (props: PropsWithChildren<{}>) => {

    // Take precedence to all component using service
    const authService = useMemo(() => {
        const config = { ...OpenAPI };
        config.BASE = resolveServerPath() + config.BASE;
        config.CREDENTIALS = "include";
        return new AuthService(config).default;
    }, []);

    return (
        <BackendServiceContext.Provider value={authService}>
            {props.children}
        </BackendServiceContext.Provider>
    )
}

export const useBackendService = () => {
    const context = useContext(BackendServiceContext);
    if (context === null) {
        throw new Error("You should wrap your component inside <BackendService> before calling useBackendService()");
    }

    return context;
}