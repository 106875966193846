import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { AccountSecurityAccessHistory } from "generated/openapi";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useBackendService } from "providers/BackendService";
import { useMemo, useState } from "react";
import { toDate } from "utils/utils";

const AccessHistoryTabPanelContentInner = () => {

    const backendService = useBackendService();

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([{ id: "timestamp", desc: true }]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 25,
    });

    const columns = useMemo<MRT_ColumnDef<AccountSecurityAccessHistory>[]>(() => ([
        {
            accessorKey: "ip",
            header: "IP address",
            enableSorting: false,
        },
        {
            accessorKey: "timestamp",
            header: "Timestamp",
            accessorFn: (row) => toDate(row.timestamp),
            muiTableHeadCellFilterTextFieldProps: {
                type: "date",
            },
            filterFn: "between",
            Cell: (tableData) => tableData.cell.getValue<Date>()?.toLocaleDateString(),
        }
    ]), []);


    const { data, isError, isFetching, isLoading } = useQuery(
        [
            columnFilters,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        async () => {
            const params: any = {
                page: pagination.pageIndex,
                numberOfRows: pagination.pageSize,
                orderBy: sorting[0]?.id,
                ordering: sorting[0]?.desc ? "desc" : "asc",
            };

            for (const filter of columnFilters) {
                if (filter.id === "timestamp") {
                    const [timestampAfter, timestampBefore] = filter.value as (Date | "")[];
                    params.timestampAfter = timestampAfter ? timestampAfter.toISOString() : undefined;
                    params.timestampBefore = timestampBefore ? timestampBefore.toISOString() : undefined;
                } else {
                    params[filter.id] = filter.value;
                }
            }

            return await backendService.getAccountSecurityAccessHistory(params);
        },
        { keepPreviousData: true },
    );

    return (
        <Box height="100%">
            <MaterialReactTable
                columns={columns}
                data={data?.data ?? []}
                manualFiltering
                manualPagination
                manualSorting
                enableStickyHeader
                enableGlobalFilter={false}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                muiTableProps={{
                    stickyHeader: true,
                }}
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.totalCount ?? 0}
                state={{
                    columnFilters,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                muiTablePaperProps={{
                    variant: "outlined",
                    sx: {
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        flexGrow: 1,
                        height: 0,
                    }
                }}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [25, 50, 100],
                }}
            />
        </Box>
    )
}

const queryClient = new QueryClient();

export const AccessHistoryTabPanelContent = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AccessHistoryTabPanelContentInner />
        </QueryClientProvider>
    )
}

export default AccessHistoryTabPanelContent;