import { Add, Remove } from "@mui/icons-material";
import { Grid, GridSize, IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import React, { isValidElement, useMemo } from "react";
import { useForm } from "./Form";
import { FormFieldValue } from "./type";

type FormFieldProps = {
    variant: "fullWidth" | "long",
    label?: React.ReactNode,
    fieldName: string,
    fieldProps?: object,
}

export const FormFieldMultipleEntry = <T extends { [x: string]: FormFieldValue[] }>(props: FormFieldProps) => {
    const { formData, disabledFields, excludedFields, onFormDataChange } = useForm<T>();

    const { label, fieldName, fieldProps } = props;

    const value = formData[fieldName];
    const disabled = disabledFields.includes(fieldName);
    const excluded = excludedFields.includes(fieldName);

    const createOnChangeListener = (index: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = [...value];
        newValue[index] = event.target.value;
        onFormDataChange(event, { [fieldName]: newValue } as Partial<T>);
    }

    const createRemoveOnClickListener = (index: number): React.MouseEventHandler<HTMLButtonElement> => (event) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onFormDataChange(event, { [fieldName]: newValue } as Partial<T>);
    }

    const handleAddOnClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const newValue = [...value];
        newValue.push("");
        onFormDataChange(event, { [fieldName]: newValue } as Partial<T>);
    }

    const renderField = () => {
        return (
            <>
                <List dense>
                    {value.map((item, i) => (
                        <ListItem
                            key={i}
                            disableGutters
                        >
                            <TextField
                                required
                                fullWidth
                                disabled={disabled}
                                name={fieldName}
                                value={item}
                                onChange={createOnChangeListener(i)}
                            />
                            <IconButton onClick={createRemoveOnClickListener(i)}>
                                <Remove />
                            </IconButton>
                        </ListItem>
                    ))}
                    <ListItem disableGutters>
                        <ListItemText />
                        <IconButton onClick={handleAddOnClick}>
                            <Add />
                        </IconButton>
                    </ListItem>
                </List>
            </>
        )
    }

    const { labelWidth, inputWidth } = useMemo<{labelWidth: GridSize, inputWidth: GridSize}>(() => {
        switch (props.variant) {
            case "fullWidth": {
                return {
                    labelWidth: 12,
                    inputWidth: 12,
                }
            }

            case "long":
            default: {
                return {
                    labelWidth: 2,
                    inputWidth: 10,
                }
            }
        }
    }, [props.variant]);

    if (excluded) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={labelWidth}>
                <List>
                    <ListItem disableGutters>
                        {isValidElement(label) ? label : (
                            <ListItemText primary={label} />
                        )}
                    </ListItem>
                </List>
            </Grid>

            <Grid item xs={inputWidth}>
                {renderField()}
            </Grid>
        </Grid>
    )
}

export default FormFieldMultipleEntry;