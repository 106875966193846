import { Checkbox, Switch, SxProps, TextField, Theme, Typography } from "@mui/material";
import React from "react";
import { useForm } from "./Form";
import FormFieldWrapper from "./FormFieldWrapper";

const deduceFieldType = (value: FormFieldValue) => {
    if (typeof value === 'boolean') {
        return "checkbox";
    }

    if (typeof value === 'number') {
        return "number";
    }

    if (value instanceof Date) {
        return "date"
    }

    return "text";
}

type FormFieldValue = string | number | boolean | Date

type FieldComponentProps<T = {}> = {
    disabled: boolean,
    value: FormFieldValue, 
    name: string, 
    onChange: (event: React.ChangeEvent<unknown>, value: string | boolean) => void,
    fieldProps?: T,
}

type FormFieldProps<T = {}> = {
    displayOnly?: boolean;
    variant: "fullWidth" | "long" | "short";
    label?: React.ReactNode;
    required?: boolean;
    fieldName: string;
    fieldType?: string;
    fieldProps?: T;
    fieldComponent?: React.ReactNode | ((props: FieldComponentProps<T>) => React.ReactNode);
    text?: React.ReactNode | ((value: FormFieldValue) => React.ReactNode);
    sx?: SxProps<Theme>;
}

export const FormField = <T extends { [x: string]: FormFieldValue }>(props: FormFieldProps) => {
    const { formData, disabledFields, excludedFields, onFormDataChange } = useForm<T>();

    const { required, fieldName, fieldProps, fieldComponent, text, displayOnly } = props;

    const value = formData[fieldName];
    const fieldType = props.fieldType || deduceFieldType(value);
    const disabled = disabledFields.includes(fieldName);
    const excluded = excludedFields.includes(fieldName);

    const renderField = () => {
        if (fieldComponent) {
            if (typeof fieldComponent === "function") {
                return fieldComponent({
                    disabled, 
                    value,
                    name: fieldName,
                    onChange: (event, value) => onFormDataChange(event, { [fieldName]: value } as Partial<T>),
                    fieldProps: props.fieldProps,
                });
            } else {
                return fieldComponent;
            }
        }

        if (displayOnly) {
            const renderText = () => {
                switch (typeof text) {
                    case "function": {
                        return text(value);
                    }

                    case "string": {
                        return text;
                    }

                    default: {
                        return value;
                    }
                }
            }


            return (
                <Typography variant="body1" {...fieldProps}>
                    <>
                        {renderText()}
                    </>
                </Typography>
            )
        }

        switch (fieldType) {
            case "checkbox": {
                return (
                    <Checkbox
                        required={required}
                        disabled={disabled}
                        name={fieldName}
                        checked={value as boolean}
                        onChange={(event, checked) => onFormDataChange(event, { [event.target.name]:checked } as Partial<T>)}
                        {...props.fieldProps}
                    />
                )
            }

            case "switch": {
                return (
                    <Switch
                        required={required}
                        disabled={disabled}
                        name={fieldName}
                        checked={value as boolean}
                        onChange={(event, checked) => onFormDataChange(event, { [event.target.name]: checked } as Partial<T>)}
                        {...props.fieldProps}
                    />
                )
            }

            default: {
                return (
                    <TextField
                        fullWidth
                        required={required}
                        multiline={fieldType === "textarea"}
                        type={fieldType === "textarea" ? "text" : fieldType}
                        disabled={disabled}
                        name={fieldName}
                        value={value}
                        onChange={(event) => onFormDataChange(event, { [event.target.name]: event.target.value } as Partial<T>)}
                        {...props.fieldProps}
                    />
                )
            }
        }
    }

    if (excluded) {
        return null;
    }

    return (
        <FormFieldWrapper
            variant={props.variant}
            label={props.label}
            field={renderField()}
            sx={props.sx}
        />
    )
}

export default FormField;