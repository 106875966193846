import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Form from "components/Form";
import { useBackendService } from "providers/BackendService";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiError } from "generated/openapi";
import PasswordFormContent from "components/PasswordFormContent";
import { validateFormData } from "utils/utils";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import LoadingBackdrop from "components/LoadingBackdrop";
import CompanyIcon from "components/CompanyIcon";
import { useTranslation } from "react-i18next";

type FormData = {
    old?: string;
    new: string;
    confirm: string;
}

const excludedFields: Array<keyof FormData> = ["old"];

export const AccountRecoveryPage = () => {
    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const backendService = useBackendService();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [isInit, setIsInit] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        const init = async () => {
            const token = searchParams.get("token");
            if (token) {
                try {
                    const response = await backendService.postAccountRecoveryInfo({
                        requestBody: {
                            token,
                        }
                    });

                    if (response.error) {
                        setError(response.error);
                    }
                } catch (error) {
                    if (error instanceof ApiError) {
                        setError(error.body || t("error_msg.unexpected"));
                    }
                }
            }

            setIsInit(true);
        }

        init();

    }, [backendService, searchParams]);

    const [formData, setFormData] = useState<FormData>({
        new: "",
        confirm: "",
    });

    const isSubmitDisabled = useMemo(() => {
        if (!validateFormData(formData)) {
            return true;
        }

        if (formData.new !== formData.confirm) {
            return true;
        }

        return false;

    }, [formData]);

    const [isRequestSuccess, setIsRequestSuccess] = useState(false);

    const handleFormOnSubmit: React.FormEventHandler = async (event) => {
        event.preventDefault();

        try {
            await backendService.postAccountRecovery({
                requestBody: {
                    token: searchParams.get("token")!,
                    password: formData.new,
                }
            });
            setIsRequestSuccess(true);
        } catch (error) {
            if (error instanceof ApiError) {
                const message = error.body || t("error_msg.recovery_failed");
                toast.error(message);
            } else {
                toast.error(t("error_msg.recovery_failed"));
            }
        }

        return false;
    }

    useEffect(() => {
        if (isRequestSuccess) {
            setTimeout(() => {
                location.href = process.env.PUBLIC_URL + "/login";
            }, 5000);
        }
    }, [isRequestSuccess, navigate]);

    const renderContent = () => {
        if (error) {
            return (
                <ErrorBox error={error} />
            )
        }

        if (!isRequestSuccess) {
            return (
                <Form
                    formData={formData}
                    onFormDataChange={(event, formData) => setFormData(formData)}
                    onSubmit={handleFormOnSubmit}
                    excludedFields={excludedFields}
                >
                    <PasswordFormContent />
                    <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        type="submit"
                        disabled={isSubmitDisabled}
                    >
                        {t("submit")}
                    </Button>
                </Form>
            )
        } else {
            return (
                <Typography variant="body1">
                    {t("account_recovery.has_been_reset")}
                </Typography>
            )
        }
    }

    if (!isInit) {
        return (
            <LoadingBackdrop open />
        )
    }

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Card
                sx={{
                    p: 2,
                    width: 720,
                    borderRadius: 8,
                }}
            >
                <CardHeader
                    title={(
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Box display="flex" height={50} borderRadius={4}>
                                <CompanyIcon />
                            </Box>
                            <Typography variant="h5" fontWeight={800}>
                                {window.__RUNTIME_ENV__.REACT_APP_ENV !== "TW" && t("deep_Translate")}
                            </Typography>
                        </Stack>
                    )}
                />
                <CardContent>
                    <Stack spacing={2}>
                        <ErrorBox error={error} />
                        {renderContent()}
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}

export default AccountRecoveryPage;