import { MoreHoriz, Person } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardHeader, IconButton, Menu, MenuItem, PopoverPosition, Toolbar, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"
import { FormField, FormFieldRadioGroup } from "components/Form";
import { FormDialog, FormDialogCloseEventHandler, FormDialogCloseReason } from "components/FormDialog/FormDialog";
import { PendingUserPayload, UserAccount } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import React, { FormEventHandler, useEffect, useState } from "react";
import { withToast } from "utils/withToast";
import { AsPromise } from "utils/types";
import { joinText } from "utils/utils";

type UsersTabContentProps = {
    data: UserAccount[];
    onFilterChange: (filter: Filter) => void;
}

type Filter = {
    username?: string,
    fullName?: string,
    roles?: Array<string>,
}

type FilterDialogProps = {
    open: boolean;
    onClose?: FormDialogCloseEventHandler;
    filter: Filter;
    onFilterSubmit: (event: React.FormEvent<Element>, filter: Filter) => void;
}

const FilterDialog = (props: FilterDialogProps) => {

    const [filterView, setFilterView] = useState<Filter>({
        username: "",
        fullName: "",
        roles: [],
    });

    useEffect(() => {
        const {
            username = "",
            fullName = "",
            roles = [],
        } = props.open ? props.filter : {};
        setFilterView({
            username,
            fullName,
            roles,
        });
    }, [props.filter, props.open]);

    const handleOnSubmit: React.FormEventHandler = (event) => {
        event.preventDefault();
        props.onFilterSubmit(event, filterView);
        return false;
    }

    return (
        <FormDialog
            title={"Search"}
            open={props.open}
            onClose={props.onClose}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs",
            }}
            formData={filterView}
            onFormDataChange={(event, formData) => setFilterView(formData)}
            onSubmit={handleOnSubmit}
        >
            <FormField
                variant="long"
                label={"Name"}
                fieldName="fullName"
            />
            <FormField
                variant="long"
                label={"Email"}
                fieldName="username"
            />

        </FormDialog>
    )
}

type CreatePendingUserDialogProps = {
    open: boolean;
    onClose?: (event: {}, reason: FormDialogCloseReason | "submitted") => void;
}

const CreatePendingUserDialog = (props: CreatePendingUserDialogProps) => {

    const backendService = useBackendService();

    const [formData, setFormData] = useState<PendingUserPayload>({
        fullName: "",
        username: "",
        role: "",
    });

    useEffect(() => {
        if (!props.open) {
            setFormData({
                fullName: "",
                username: "",
                role: "",
            });
        }
    }, [props.open]);

    const handleFormOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();

        await withToast({
            success: "Created pending user",
            error: true
        }, async () => {
            await backendService.postCompanyPendingUsers({
                requestBody: formData
            });
        });

        props.onClose && props.onClose(event, "submitted");

        return false;
    }

    return (
        <FormDialog
            open={props.open}
            onClose={props.onClose}
            title={"Create user"}
            dialogProps={{
                fullWidth: true,
                maxWidth: "xs",
            }}
            formData={formData}
            onFormDataChange={(event, formData) => setFormData(formData)}
            onSubmit={handleFormOnSubmit}
        >
            <FormField
                required
                variant="long"
                label={"Name"}
                fieldName="fullName"
            />
            <FormField
                required
                variant="long"
                label={"Email"}
                fieldName="username"
                fieldType="email"
            />
            <FormFieldRadioGroup
                required
                variant="long"
                label={"Role"}
                fieldName="role"
                options={[
                    {
                        label: "Admin",
                        value: "TRANSL_CO_ADMIN",
                    },
                    {
                        label: "User",
                        value: "TRANSL_CO_USER",
                    }
                ]}
            />
        </FormDialog>
    )


}

export const PendingUsersTabContent = (props: UsersTabContentProps) => {

    const backendService = useBackendService();

    const { data, onFilterChange } = props;

    const [openedDialog, setOpenedDialog] = useState<"" | "filter-dialog" | "create-pending-user-dialog">("");

    const [filter, setFilter] = useState<Filter>({});

    const handleFilterOnSubmit = (event: React.FormEvent, filter: Filter) => {
        setFilter(filter);
        props.onFilterChange(filter);
        setOpenedDialog("");
    }

    const renderFilterText = () => {
        const {
            fullName,
            username,
            roles,
        } = filter;

        const filters = [];
        if (fullName) {
            filters.push(`name contains "${fullName}"`);
        }
        if (username) {
            filters.push(`user email contains "${username}"`);
        }
        if (roles && roles.length > 0) {
            filters.push(`role is ${joinText(roles, "or")}`);
        }

        if (filters.length > 0) {
            return (
                <Typography variant="subtitle2">
                    {"Search by: "}{filters.join("; ")}
                </Typography>
            )
        }
    }

    const handleCreatePendingFormDialogOnClose = (event: {}, reason: FormDialogCloseReason | "submitted") => {
        if (reason === "submitted") {
            setFilter({});
            props.onFilterChange({});
        }
        setOpenedDialog("");
    }

    const [selectedUser, setSelectedUser] = useState<UserAccount>();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const createMenuButtonOnClickHandler = (userAccount: UserAccount): React.MouseEventHandler<HTMLElement> => (event) => {
        event.stopPropagation();
        setSelectedUser(userAccount);
        setAnchorEl(event.target as HTMLElement);
    }

    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>();
    const createOnContextMenuHandler = (userAccount: UserAccount): React.MouseEventHandler => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedUser(userAccount);
        setAnchorPosition({
            left: event.clientX,
            top: event.clientY,
        });
    }

    const closeMenu = () => {
        setSelectedUser(undefined);
        setAnchorPosition(undefined);
        setAnchorEl(undefined);
    }

    const reload = () => onFilterChange(filter);

    const renderMenu = () => {
        if (selectedUser) {
            const { username } = selectedUser;

            const createActionHandler = (callback: AsPromise<React.MouseEventHandler>): React.MouseEventHandler => async (event) => {
                closeMenu();
                await callback(event);
                reload();
            }

            const handleSendConfirmationEmailOnClick = async () => {
                await withToast({
                    success: "Sent confirmation email",
                    error: true
                }, async () => {
                    await backendService.postCompanyPendingUsersSendConfirmationEmail({
                        requestBody: username
                    });
                });
            }

            const handleRemoveOnClick = async () => {
                await withToast({
                    success: "Removed pending user",
                    error: true
                }, async () => {
                    await backendService.postCompanyPendingUsersRemove({
                        requestBody: username
                    });
                });
            }

            return (
                <Menu
                    open={!!selectedUser}
                    anchorEl={anchorEl}
                    anchorPosition={anchorPosition}
                    anchorReference={anchorPosition ? "anchorPosition" : "anchorEl"}
                    onClose={() => closeMenu()}
                >
                    <MenuItem onClick={createActionHandler(handleSendConfirmationEmailOnClick)}>
                        {"Send confirmation email"}
                    </MenuItem>
                    <MenuItem onClick={createActionHandler(handleRemoveOnClick)}>
                        {"Remove"}
                    </MenuItem>
                </Menu>
            )
        }

        return null;
    }

    return (
        <>
            <Toolbar disableGutters>
                <Box flexGrow={1}>
                    <Typography variant="h6">
                        {"Pending Users"}
                    </Typography>
                    {renderFilterText()}
                </Box>

                <Button onClick={() => setOpenedDialog("filter-dialog")}>
                    {"Search"}
                </Button>
                <Button onClick={() => setOpenedDialog("create-pending-user-dialog")}>
                    {"Add"}
                </Button>
            </Toolbar>
            <Grid2 container spacing={1}>
                {data.map((userAccount) => (
                    <Grid2 key={userAccount.username} md={6} xs={12}>
                        <Card variant="outlined">
                            <CardActionArea onContextMenu={createOnContextMenuHandler(userAccount)} component="div">
                                <CardHeader
                                    avatar={<Person color="disabled" />}
                                    title={userAccount.fullName}
                                    subheader={userAccount.username}
                                    action={(
                                        <IconButton onClick={createMenuButtonOnClickHandler(userAccount)}>
                                            <MoreHoriz />
                                        </IconButton>
                                    )}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid2>
                ))}
            </Grid2>
            <FilterDialog
                open={openedDialog === "filter-dialog"}
                onClose={() => setOpenedDialog("")}
                filter={filter}
                onFilterSubmit={handleFilterOnSubmit}
            />
            <CreatePendingUserDialog
                open={openedDialog === "create-pending-user-dialog"}
                onClose={handleCreatePendingFormDialogOnClose}
            />
            {renderMenu()}
        </>
    )
}

export default PendingUsersTabContent;