import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Form, { FormField } from "components/Form";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useBackendService } from "providers/BackendService";
import { ApiError } from "generated/openapi";
import { useLoadingBackdrop } from "components/LoadingBackdrop/LoadingBackdrop";
import CompanyIcon from "components/CompanyIcon";
import { useTranslation } from "react-i18next";

type FormData = {
    email: string;
}

export const ForgetPasswordPage = () => {

    const { withLoadingBackdrop } = useLoadingBackdrop()

    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const backendService = useBackendService();

    const [formData, setFormData] = useState<FormData>({
        email: "",
    });

    const [isRequestSuccess, setIsRequestSuccess] = useState(false);

    const handleFormOnSubmit: React.FormEventHandler = async (event) => {
        event.preventDefault();

        try {
            await withLoadingBackdrop(async () => {
                await backendService.postForgetPassword({ requestBody: formData.email });
            });
            setIsRequestSuccess(true);
        } catch (error) {
            if (error instanceof ApiError && error.status === 404) {
                toast.error(t("error_msg.email_not_found"));
            } else {
                toast.error(t("error_msg.contact_CS"));
            }
        }

        return false;
    }

    const renderContent = () => {
        if (!isRequestSuccess) {
            return (
                <Form
                    formData={formData}
                    onFormDataChange={(event, formData) => setFormData(formData)}
                    onSubmit={handleFormOnSubmit}
                >
                    <FormField
                        required
                        variant="fullWidth"
                        label={t("forget_password.enter_email")}
                        fieldName="email"
                        fieldType="email"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        type="submit"
                    >
                        {t("submit")}
                    </Button>
                </Form>
            )
        } else {
            return (
                <Typography variant="body1">
                    {t("forget_password.email_send")}
                </Typography>
            )
        }
    }

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Card
                sx={{
                    p: 2,
                    width: 480,
                    borderRadius: 8,
                }}
            >
                <CardHeader
                    title={(
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Box display="flex" height={50} borderRadius={4}>
                                <CompanyIcon />
                            </Box>
                            <Typography variant="h5" fontWeight={800}>
                                {window.__RUNTIME_ENV__.REACT_APP_ENV !== "TW" && t("deep_Translate")}
                            </Typography>
                        </Stack>
                    )}
                />
                <CardContent>
                    {renderContent()}
                </CardContent>
            </Card>
        </Box>
    )
}

export default ForgetPasswordPage;