import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { noop } from "utils/utils";
import ConfirmDialog from "./ConfirmDialog";

type ConfirmDialogContextState = {
    confirmOnEnter?: boolean;
    type?: "warning" | "error" | "inherit" | "primary" | "secondary" | "success" | "info";
    title?: string;
    content?: string;
    onConfirm?: (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    onClose?: (event: {} | React.MouseEvent<HTMLButtonElement>, reason?: "backdropClick" | "escapeKeyDown") => void;
}

type OpenConfirmDialogOptions = ConfirmDialogContextState;

type ConfirmDialogContextValue = {
    openConfirmDialog: (options: OpenConfirmDialogOptions) => void;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextValue | null>(null);

export const ConfirmDialogProvider = (props: PropsWithChildren<{}>) => {

    const [state, setState] = useState<OpenConfirmDialogOptions>({
        onConfirm: noop,
        onClose: noop,
    });

    const [isOpen, setIsOpen] = useState(false);

    const { onConfirm, onClose, ...otherProps } = state;

    const handleOnConfirm = (event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
        setIsOpen(false);
        onConfirm && onConfirm(event);
    }

    const handleOnClose = (event: {} | React.MouseEvent<HTMLButtonElement>, reason?: "backdropClick" | "escapeKeyDown") => {
        setIsOpen(false);
        onClose && onClose(event, reason);
    }

    const context = useMemo<ConfirmDialogContextValue>(() => ({
        openConfirmDialog: (options) => {
            setIsOpen(true);
            setState(options)
        },
    }), []);

    return (
        <ConfirmDialogContext.Provider value={context}>
            {props.children}
            <ConfirmDialog
                open={isOpen}
                onConfirm={handleOnConfirm}
                onClose={handleOnClose}
                {...otherProps}
            />
        </ConfirmDialogContext.Provider>
    )
}

export const useConfirmDialog = () => {
    const context = useContext(ConfirmDialogContext);
    if (context === null) {
        throw new Error("You should wrap your component inside <ConfirmDialogProvider> before calling useConfirmDialog()");
    }

    return context;
}

export default ConfirmDialogProvider;