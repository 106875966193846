import { Container, ContainerProps, Paper, PaperProps } from "@mui/material";
import { PropsWithChildren } from "react";

type PageContainerProps = {
    variant?: "elevation" | "outlined"
    muiContainerProps?: Omit<ContainerProps, "children">;
    muiPaperProps?: Omit<PaperProps, "children">;
}

export const PageContainer = (props: PropsWithChildren<PageContainerProps>) => {
    const { muiContainerProps, muiPaperProps } = props;

    const { sx: containerSx, ...otherContainerProps } = muiContainerProps ?? {};
    const { sx: paperSx, ...otherPaperProps } = muiPaperProps ?? {};

    return (
        <Container
            sx={[{
                height: "100%",
                p: 2,
            }, ...(Array.isArray(containerSx) ? containerSx : [containerSx])]}
            {...otherContainerProps}
        >
            <Paper
                variant={props.variant}
                sx={[{
                    height: "100%",
                }, ...(Array.isArray(paperSx) ? paperSx : [paperSx])]}
                {...otherPaperProps}
            >
                {props.children}
            </Paper>
        </Container>
    )
}

export default PageContainer;