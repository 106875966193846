import { MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useConfirmDialog } from "components/ConfirmDialog/ConfirmDialogProvider";
import { FormDialogCloseReason } from "components/FormDialog/FormDialog";
import { useLoadingBackdrop } from "components/LoadingBackdrop/LoadingBackdrop";
import { RegisteredClient } from "generated/openapi";
import MaterialReactTable, { MRT_ColumnDef, MRT_Row, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton } from "material-react-table";
import { useBackendService } from "providers/BackendService";
import React, { FormEvent, MouseEventHandler, useMemo, useRef, useState } from "react";
import { withToast } from "utils/withToast";
import { toDate } from "utils/utils";
import { CreateClientDialog } from "./CreateClientDialog";
import EditClientDialog from "./EditClientDialog";

const renderStringArrayCell: MRT_ColumnDef<RegisteredClient>["Cell"] = (tableData) => {
    return (
        <Box whiteSpace="pre-wrap">
            {tableData.cell.getValue<String[]>()?.join("\n")}
        </Box>
    )
}

const ClientConsoleInner = () => {

    const backendService = useBackendService();

    const { openConfirmDialog } = useConfirmDialog();

    const { withLoadingBackdrop } = useLoadingBackdrop();

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const columns = useMemo<MRT_ColumnDef<RegisteredClient>[]>(() => ([
        {
            accessorKey: "clientName",
            header: "Client Name",
        },
        {
            accessorKey: "clientId",
            header: "client_id",
        },
        {
            id: "clientIdIssuedAt",
            accessorFn: (row) => toDate(row.clientIdIssuedAt),
            header: "Issued At",
            muiTableHeadCellFilterTextFieldProps: {
                type: "date",
            },
            sortingFn: "datetime",
            filterFn: "between",
            Cell: (tableData) => tableData.cell.getValue<Date>()?.toLocaleDateString(),
        },
        {
            accessorKey: "clientAuthenticationMethods",
            header: "Client Authentication Methods",
            enableSorting: false,
            Cell: renderStringArrayCell,
            filterVariant: "multi-select",
            filterSelectOptions: [
                {
                    text: "none",
                    value: "NONE",
                },
                {
                    text: "client_secret_basic",
                    value: "CLIENT_SECRET_BASIC",
                },
                {
                    text: "client_secret_post",
                    value: "CLIENT_SECRET_POST",
                }
            ],
        },
        {
            accessorKey: "authorizationGrantTypes",
            header: "Authorization Grant Types",
            enableSorting: false,
            Cell: renderStringArrayCell,
            filterVariant: "multi-select",
            filterSelectOptions: [
                {
                    text: "authorization_code",
                    value: "AUTHORIZATION_CODE",
                },
                {
                    text: "refresh_token",
                    value: "REFRESH_TOKEN",
                },
                {
                    text: "client_credentials",
                    value: "CLIENT_CREDENTIALS",
                }
            ],
        },
        {
            accessorKey: "redirectUris",
            header: "Redirect URIs",
            enableSorting: false,
            Cell: renderStringArrayCell,
        },
        {
            accessorKey: "scopes",
            header: "Scopes",
            enableSorting: false,
            Cell: renderStringArrayCell,
        }
    ]), []);

    const { data, isError, isFetching, isLoading, refetch } = useQuery(
        [
            columnFilters,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        async () => {
            const params: any = {
                page: pagination.pageIndex,
                numberOfRows: pagination.pageSize,
            };

            for (const filter of columnFilters) {
                if (filter.id === "clientIdIssuedAt") {
                    const [clientIdIssuedAfter, clientIdIssuedBefore] = filter.value as (Date | "")[];
                    params.clientIdIssuedAfter = clientIdIssuedAfter ? clientIdIssuedAfter.toISOString() : undefined;
                    params.clientIdIssuedBefore = clientIdIssuedBefore ? clientIdIssuedBefore.toISOString() : undefined;
                } else {
                    params[filter.id] = filter.value;
                }
            }

            if (sorting.length === 1) {
                params.orderBy = sorting[0].id;
                params.ordering = sorting[0].desc ? "desc" : "asc";
            }

            return await backendService.getSystemClients(params);
        },
        { keepPreviousData: true },
    );

    const createRemoveClientOnClickListener = (callback: React.MouseEventHandler<HTMLLIElement>, row: MRT_Row<RegisteredClient>): React.MouseEventHandler<HTMLLIElement> => (event) => {
        callback(event);
        openConfirmDialog({
            type: "error",
            title: "Warning",
            content: `Are you sure to remove client [${row.getValue("clientName")}]?`,
            onConfirm: async () => {
                await withLoadingBackdrop(async () => {
                    await withToast({
                        success: "Deleted client",
                        error: "Failed to delete client",
                    }, async () => {
                        await backendService.deleteSystemClients({ id: row.original.id! });
                    });
                });
                refetch();
            }
        });
    }

    const createEditClientOnClickListener = (callback: React.MouseEventHandler<HTMLLIElement>, row: MRT_Row<RegisteredClient>): React.MouseEventHandler<HTMLLIElement> => (event) => {
        callback(event);
        setSelectedClient(row.original);
        setOpenedDialog("edit-client");
    }

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const createMenuItemOnClickListener = (callback: React.MouseEventHandler<HTMLLIElement>): React.MouseEventHandler<HTMLLIElement> => (event) => {
        setIsMenuOpen(false);
        callback(event);
    }

    const [openedDialog, setOpenedDialog] = useState<"create-client" | "edit-client" | "">("");

    const [selectedClient, setSelectedClient] = useState<RegisteredClient>();

    const handleDialogOnClose: ((event: React.MouseEvent | FormEvent, reason: FormDialogCloseReason | "submit_success") => void) = (event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        
        if (reason === "submit_success") {
            refetch();
        }
        setOpenedDialog("");
        setSelectedClient(undefined);
    }

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={data?.data ?? []}
                manualFiltering
                manualPagination
                manualSorting
                enableStickyHeader
                enableGlobalFilter={false}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                muiTableProps={{
                    stickyHeader: true,
                }}
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.totalCount ?? 0}
                state={{
                    columnFilters,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <Tooltip arrow title={"More"}>
                            <IconButton ref={menuButtonRef} onClick={() => setIsMenuOpen(true)}>
                                <MoreHoriz />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                muiTablePaperProps={{
                    sx: {
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        flexGrow: 1,
                        height: 0,
                    }
                }}
                enableRowActions
                positionActionsColumn="last"
                renderRowActionMenuItems={({ closeMenu, row, table }) => ([
                    (
                        <MenuItem key={0} onClick={createRemoveClientOnClickListener(closeMenu, row)}>
                            {"Remove this client"}
                        </MenuItem>
                    ),
                    (
                        <MenuItem key={1} onClick={createEditClientOnClickListener(closeMenu, row)}>
                            {"Edit this client"}
                        </MenuItem>
                    )
                ])}
            />
            <Menu
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                anchorEl={menuButtonRef.current}
            >
                <MenuItem onClick={createMenuItemOnClickListener(() => setOpenedDialog("create-client"))}>
                    {"Create Client"}
                </MenuItem>
            </Menu>
            <CreateClientDialog
                open={openedDialog === "create-client"}
                onClose={handleDialogOnClose}
            />
            <EditClientDialog
                open={openedDialog === "edit-client"}
                onClose={handleDialogOnClose}
                client={selectedClient}
            />
        </>
    )
}

const queryClient = new QueryClient();

const ClientConsole = () => (
    <QueryClientProvider client={queryClient}>
        <ClientConsoleInner />
    </QueryClientProvider>
);

export default ClientConsole;