/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountProfile } from '../models/AccountProfile';
import type { AccountRecoveryInfoResponse } from '../models/AccountRecoveryInfoResponse';
import type { AccountSecurity } from '../models/AccountSecurity';
import type { AccountSecurityAccessHistory } from '../models/AccountSecurityAccessHistory';
import type { AuthorizationGrantType } from '../models/AuthorizationGrantType';
import type { ClientAuthenticationMethod } from '../models/ClientAuthenticationMethod';
import type { PaginationResponse } from '../models/PaginationResponse';
import type { PendingUserPayload } from '../models/PendingUserPayload';
import type { PublicConfig } from '../models/PublicConfig';
import type { RegisteredClient } from '../models/RegisteredClient';
import type { RegisteredClientCredentials } from '../models/RegisteredClientCredentials';
import type { RegisteredClientPayload } from '../models/RegisteredClientPayload';
import type { UserAccount } from '../models/UserAccount';
import type { UserStatus } from '../models/UserStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get public configuration   Only configurations which are safe for public access will be returned
     * @returns PublicConfig OK
     * @throws ApiError
     */
    public getPublicConfig(): CancelablePromise<PublicConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/config',
        });
    }

    /**
     * Sendi reset password email
     * @returns any OK
     * @throws ApiError
     */
    public postForgetPassword({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/forget-password',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                404: `Email not found`,
            },
        });
    }

    /**
     * Reset password
     * @returns any OK
     * @throws ApiError
     */
    public postAccountRecovery({
        requestBody,
    }: {
        requestBody?: {
            token: string;
            password: string;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-recovery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Token not found`,
            },
        });
    }

    /**
     * Validate token
     * @returns AccountRecoveryInfoResponse OK
     * @throws ApiError
     */
    public postAccountRecoveryInfo({
        requestBody,
    }: {
        requestBody?: {
            token: string;
        },
    }): CancelablePromise<AccountRecoveryInfoResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-recovery/info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Confirm registration
     * @returns any OK
     * @throws ApiError
     */
    public postAccountRegistrationConfirm({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-registration/confirm',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Get account profile
     * @returns AccountProfile OK
     * @throws ApiError
     */
    public getAccountProfile(): CancelablePromise<AccountProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/account-profile',
            errors: {
                401: `You must provide authentication details to use this resource`,
            },
        });
    }

    /**
     * Update account profile
     * @returns any OK
     * @throws ApiError
     */
    public putAccountProfile({
        requestBody,
    }: {
        requestBody?: {
            fullName?: string;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/account-profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
            },
        });
    }

    /**
     * Get account security information
     * @returns AccountSecurity OK
     * @throws ApiError
     */
    public getAccountSecurity(): CancelablePromise<AccountSecurity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/account-security',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Get account access history
     * @returns any OK
     * @throws ApiError
     */
    public getAccountSecurityAccessHistory({
        page,
        numberOfRows,
        orderBy,
        ordering,
        ip,
        timestampBefore,
        timestampAfter,
    }: {
        page?: number,
        numberOfRows?: number,
        orderBy?: string,
        ordering?: 'asc' | 'desc',
        ip?: string,
        timestampBefore?: string,
        timestampAfter?: string,
    }): CancelablePromise<(PaginationResponse & {
        data?: Array<AccountSecurityAccessHistory>;
    })> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/account-security/access-history',
            query: {
                'page': page,
                'numberOfRows': numberOfRows,
                'orderBy': orderBy,
                'ordering': ordering,
                'ip': ip,
                'timestampBefore': timestampBefore,
                'timestampAfter': timestampAfter,
            },
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Update password
     * @returns any OK
     * @throws ApiError
     */
    public postAccountSecurityPassword({
        requestBody,
    }: {
        requestBody?: {
            old?: string;
            new?: string;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-security/password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Get OTP url
     * @returns string OK
     * @throws ApiError
     */
    public getAccountSecurityOtpActivate(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/account-security/otp/activate',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Activate OTP
     * @returns any OK
     * @throws ApiError
     */
    public postAccountSecurityOtpActivate({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-security/otp/activate',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Deactivate OTP
     * @returns any OK
     * @throws ApiError
     */
    public postAccountSecurityOtpDeactivate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/account-security/otp/deactivate',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Get users of the company
     * @returns any OK
     * @throws ApiError
     */
    public getCompanyUsers({
        page,
        numberOfRows,
        orderBy,
        ordering,
        username,
        fullName,
        roles,
        billing,
        statuses,
    }: {
        page?: number,
        numberOfRows?: number,
        orderBy?: string,
        ordering?: 'asc' | 'desc',
        username?: string,
        fullName?: string,
        roles?: Array<string>,
        billing?: boolean,
        statuses?: Array<UserStatus>,
    }): CancelablePromise<(PaginationResponse & {
        data?: Array<UserAccount>;
    })> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/users',
            query: {
                'page': page,
                'numberOfRows': numberOfRows,
                'orderBy': orderBy,
                'ordering': ordering,
                'username': username,
                'fullName': fullName,
                'roles': roles,
                'billing': billing,
                'statuses': statuses,
            },
        });
    }

    /**
     * Mark user as billing user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyUsersBilling({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/users/billing',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Activate user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyUsersActivate({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/users/activate',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Deactivate user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyUsersDeactivate({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/users/deactivate',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Unlock user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyUsersUnlock({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/users/unlock',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Get users of the company
     * @returns any OK
     * @throws ApiError
     */
    public getCompanyPendingUsers({
        page,
        numberOfRows,
        orderBy,
        ordering,
        username,
        fullName,
        roles,
    }: {
        page?: number,
        numberOfRows?: number,
        orderBy?: string,
        ordering?: 'asc' | 'desc',
        username?: string,
        fullName?: string,
        roles?: Array<string>,
    }): CancelablePromise<(PaginationResponse & {
        data?: Array<UserAccount>;
    })> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/company/pending-users',
            query: {
                'page': page,
                'numberOfRows': numberOfRows,
                'orderBy': orderBy,
                'ordering': ordering,
                'username': username,
                'fullName': fullName,
                'roles': roles,
            },
        });
    }

    /**
     * Create pending user and send confirmation email
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyPendingUsers({
        requestBody,
    }: {
        requestBody?: PendingUserPayload,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/pending-users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Send confirmation email to pending user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyPendingUsersSendConfirmationEmail({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/pending-users/send-confirmation-email',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Remove pending user
     * @returns any OK
     * @throws ApiError
     */
    public postCompanyPendingUsersRemove({
        requestBody,
    }: {
        requestBody?: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/company/pending-users/remove',
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Get registered clients
     * @returns any OK
     * @throws ApiError
     */
    public getSystemClients({
        page,
        numberOfRows,
        orderBy,
        ordering,
        clientName,
        clientId,
        clientIdIssuedBefore,
        clientIdIssuedAfter,
        clientAuthenticationMethods,
        authorizationGrantTypes,
        redirectUris,
        scopes,
    }: {
        page?: number,
        numberOfRows?: number,
        orderBy?: string,
        ordering?: 'asc' | 'desc',
        clientName?: string,
        clientId?: string,
        clientIdIssuedBefore?: string,
        clientIdIssuedAfter?: string,
        clientAuthenticationMethods?: Array<ClientAuthenticationMethod>,
        authorizationGrantTypes?: Array<AuthorizationGrantType>,
        redirectUris?: Array<string>,
        scopes?: Array<string>,
    }): CancelablePromise<(PaginationResponse & {
        data?: Array<RegisteredClient>;
    })> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/system/clients',
            query: {
                'page': page,
                'numberOfRows': numberOfRows,
                'orderBy': orderBy,
                'ordering': ordering,
                'clientName': clientName,
                'clientId': clientId,
                'clientIdIssuedBefore': clientIdIssuedBefore,
                'clientIdIssuedAfter': clientIdIssuedAfter,
                'clientAuthenticationMethods': clientAuthenticationMethods,
                'authorizationGrantTypes': authorizationGrantTypes,
                'redirectUris': redirectUris,
                'scopes': scopes,
            },
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
            },
        });
    }

    /**
     * Create registered client
     * Create registered client which conforms to OAuth 2.1 specification
     * @returns RegisteredClientCredentials OK
     * @throws ApiError
     */
    public postSystemClients({
        requestBody,
    }: {
        requestBody?: (RegisteredClientPayload & {
            username?: string;
        } & {
            username: string;
        }),
    }): CancelablePromise<RegisteredClientCredentials> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/system/clients',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
                404: `Username not found`,
            },
        });
    }

    /**
     * Update client
     * @returns any OK
     * @throws ApiError
     */
    public putSystemClients({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: RegisteredClient,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/system/clients/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
                404: `Client not found`,
            },
        });
    }

    /**
     * Delete client
     * @returns any OK
     * @throws ApiError
     */
    public deleteSystemClients({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/system/clients/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `You must provide authentication details to use this resource`,
                403: `You are not permitted to use this resource`,
                404: `Client not found`,
            },
        });
    }

}
