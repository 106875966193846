import { Stack } from "@mui/material"
import PwdCard from "./PwdCard"
import TwoFactorAuthenticationCard from "./TwoFactorAuthenticationCard"

export const AccountSecurityTabContent = () => {
    return (
        <>
            <Stack spacing={2}>
                <PwdCard />
                <TwoFactorAuthenticationCard />
            </Stack>
        </>
    )
}

export default AccountSecurityTabContent;