import { PropsWithChildren } from "react";
import oauth2Api from "store/apis/oauth2";

type HasAppProps = {
    app: string;
}

export const HasApp = (props: PropsWithChildren<HasAppProps>) => {

    const { isLoading, data } = oauth2Api.useOauth2UserInfoGetQuery(undefined);

    if (data?.apps.includes(props.app)) {
        return (
            <>
                {props.children}
            </>
        )
    }

return null;
}

export default HasApp;