import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormField } from "components/Form";
import { FormDialog, FormDialogCloseEventHandler } from "components/FormDialog/FormDialog";
import { ApiError } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { toast } from "react-toastify";

type FormData = {
    otpCode: string;
}

type ActivateOtpDialogProps = {
    open: boolean;
    onClose?: FormDialogCloseEventHandler & ((event: FormEvent, reason: "submit_success") => void)
}

export const ActivateOtpDialog = (props: ActivateOtpDialogProps) => {

    const backendService = useBackendService();

    const [formData, setFormData] = useState<FormData>({
        otpCode: ""
    });

    const [qrCodeUrl, setQrCodeUrl] = useState("");

    useEffect(() => {
        const init = async () => {
            const response = await backendService.getAccountSecurityOtpActivate();
            setQrCodeUrl(response);
        }
        init();
    }, [backendService]);

    const handleOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();

        try {
            await backendService.postAccountSecurityOtpActivate({
                requestBody: formData.otpCode,
            });
            toast.success("Activated");
            props.onClose && props.onClose(event, "submit_success");
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error(error.message);
            }
        }

        return false;
    }

    return (
        <FormDialog
            open={props.open}
            onClose={props.onClose}
            title={"Activate OTP"}
            formData={formData}
            onFormDataChange={(event, formData) => setFormData(formData)}
            onSubmit={handleOnSubmit}
        >
            <Grid2 container spacing={2}>
                <Grid2 xs>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel >
                                {"Scan the QR Code in Google Authenticator"}
                            </StepLabel>
                        </Step>
                        <Step active>
                            <StepLabel >
                                {"Enter the OTP code"}
                            </StepLabel>
                            <StepContent>
                                <FormField
                                    variant="fullWidth"
                                    fieldName="otpCode"
                                />
                            </StepContent>
                        </Step>
                    </Stepper>
                </Grid2>
                <Grid2 xs={5}>
                    <img src={qrCodeUrl} alt="" />
                </Grid2>
            </Grid2>
        </FormDialog>
    )
    
}

export default ActivateOtpDialog;