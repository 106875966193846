/**
 * Returns `REACT_APP_SERVER_ROUTE` if it is not empty, otherwise returns `PUBLIC_URL`  
 * In development mode, the webpack dev server will be responsible for routing requests to the backend server.  
 * In production mode, requests are routed by the backend server.
 */
export const resolveServerPath = () => {
    return process.env.REACT_APP_SERVER_ROUTE || process.env.PUBLIC_URL;
}

export const resolvePublicUrl = (path: string) => {
    // remove trailing slash of PUBLIC_URL and ensure path starts with a slash
    return process.env.PUBLIC_URL.replace(/\/$/, "") + "/" + path.replace(/^\//, "");
}

export const objectUpdater = <T>(key: keyof T, value: any) => (object: T) => ({
    ...object,
    [key]: value,
});

export const intersect = (list1: string[], list2: string[]) => {
    return list1.some((item) => list2.includes(item));
}

export const toDate = (dateString?: string) => {
    if (dateString) {
        const ms = Date.parse(dateString);
        if (ms) {
            return new Date(ms);
        }
    }
    return null;
}

export const joinText = (textList: string[], joinBy: "or" | "and") => {
    if (textList.length == 0) {
        return "";
    }

    if (textList.length == 1) {
        return textList[0];
    }

    const clone = [...textList];
    const lastText = clone.pop();

    return clone.join(", ") + ` ${joinBy} ${lastText}`;
}

export const noop = () => { };
export const asyncNoop = async () => {};

export const validateFormData = (formData: Record<string, any>) => {
    return !Object.values(formData).some((value) => {
        return value === null || value === undefined || value === "";
    });
}
