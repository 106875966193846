import { Backdrop, CircularProgress } from '@mui/material';
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type LoadingBackdropProps = {
    open: boolean;
}

export const LoadingBackdrop = (props: LoadingBackdropProps) => {
    return (
        <Backdrop 
            open={props.open}
            color="#fff"
            sx={(theme) => ({
                position: "absolute",
                zIndex: theme.zIndex.modal + 1,
            })}
         >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}


type LoadingBackdropContextValue = {
    withLoadingBackdrop: <T extends (...args: any[]) => any> (callback: T) => Promise<ReturnType<T>>;
}

const LoadingBackdropContext = createContext<LoadingBackdropContextValue | null>(null);

export const LoadingBackdropProivder = (props: PropsWithChildren<{}>) => {

    const [count, setCount] = useState(0);

    const contextValue = useMemo<LoadingBackdropContextValue>(() => {
        return {
            withLoadingBackdrop: async (callback) => {
                setCount((count) => count + 1);
                try {
                    return await callback();
                } finally {
                    setCount((count) => count - 1);
                }
            }
        }
    }, []);

    return (
        <LoadingBackdropContext.Provider value={contextValue}>
            {props.children}
            <LoadingBackdrop open={count > 0} />
        </LoadingBackdropContext.Provider>
    )
}

export const useLoadingBackdrop = () => {
    const context = useContext(LoadingBackdropContext);
    if (context === null) {
        throw new Error("You should wrap your component inside <LoadingBackdropProivder> before calling useLoadingBackdrop()");
    }

    return context;
}

export default LoadingBackdrop;
