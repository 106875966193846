import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';
import * as yaml from 'js-yaml';

const supportedLanguages = [
  "en",
  "zh-HK",
  "zh-CN",
  "en-HKEX"
] as const;

export type SupportedLanguage = typeof supportedLanguages[number];

const loadPath = `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.yml`.replace(
  /\/\//g,
  '/'
);

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(LanguageDetector)
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    backend: {
      loadPath : loadPath,
      parse: (data: string) => {
        return yaml.load(data) as { [key: string]: any; };
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }, 
    detection: {
      cookieDomain: window.__RUNTIME_ENV__.REACT_APP_COOKIE_DOMAIN,
      caches: ["cookie"],
      convertDetectedLanguage: (code: string) => {
        if (supportedLanguages.includes(code as SupportedLanguage)) {
          return code;
        }

        if (code.startsWith("zh")) {
          return "zh-HK";
        }

        return "en";
      }
    },
  });

export default i18n;
