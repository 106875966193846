import { Button, Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Form, { FormField } from "components/Form";
import { useAccountProfile } from "features";
import { AccountProfile } from "generated/openapi/models/AccountProfile";
import { useBackendService } from "providers/BackendService";
import { FormEventHandler, useEffect, useState } from "react";
import { withToast } from "utils/withToast";

const disabledFields: Array<keyof AccountProfile> = ["username", "companyName", "role"];

const toLabel = (role: string) => {
    if (role.includes("ADMIN")) {
        if (role !== "SYS_ADMIN") {
            return "Admin";
        }
    } else if (role.includes("USER")) {
        return "User";
    }

    return role;
}

export const AccountProfileTabContent = () => {

    const backendService = useBackendService();
    const { accountProfile, refetch } = useAccountProfile();

    const [formData, setFormData] = useState<AccountProfile>({
        fullName: "",
        username: "",
        companyName: "",
        role: [],
    });

    useEffect(() => {
        setFormData({ ...accountProfile });
    }, [accountProfile]);

    const handleFormOnReset: FormEventHandler = (event) => {
        event.preventDefault();
        setFormData({ ...accountProfile });
    }

    const handleFormOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();
        await withToast({
            success: "Updated account profile",
            error: true,
        }, async () => {
            await backendService.putAccountProfile({
                requestBody: {
                    fullName: formData.fullName,
                },
            })
        });
        await refetch();
        return false;
    }

    return (
        <Box display="flex">
            <Form
                formData={formData}
                onFormDataChange={(event, formData) => setFormData(formData)}
                disabledFields={disabledFields}
                onReset={handleFormOnReset}
                onSubmit={handleFormOnSubmit}
            >
                <FormField
                    variant="long"
                    label={"Full Name"}
                    fieldName="fullName"
                />
                <FormField
                    variant="long"
                    label={"Company"}
                    fieldName="companyName"
                />
                <FormField
                    variant="long"
                    label={"Role"}
                    fieldName="role"
                    fieldComponent={({ value }) => (
                        <Stack direction="row" spacing={1}>
                            {(value as any as string[])?.map((role) => (
                                <Chip label={toLabel(role)} />
                            ))}
                        </Stack>
                    )}
                />
                <Box flexGrow={1} />
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        type="reset"
                    >
                        {"Reset"}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        {"Update"}
                    </Button>
                </Box>
            </Form>
        </Box>
    )
}

export default AccountProfileTabContent;