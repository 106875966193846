import { LoadingBackdrop } from "components/LoadingBackdrop";
import { PropsWithChildren, useMemo } from "react";
import { Navigate } from "react-router-dom";
import oauth2Api from "store/apis/oauth2";
import { intersect } from "utils/utils";

type HasRoleProps = {
    roles?: string[];
}

export const HasRole = (props: PropsWithChildren<HasRoleProps>) => {

    const { isLoading, data } = oauth2Api.useOauth2UserInfoGetQuery(undefined);

    const isRouteAuthenticated = useMemo(() => {
        if (data) {
            if (props.roles) {
                if (!intersect(props.roles, data?.roles)) {
                    return false;
                }
            }
        }
        return true;
    }, [data, props.roles]);

    if (isLoading) {
        return <LoadingBackdrop open={true} />
    }

    if (isRouteAuthenticated) {
        return (
            <>
                {props.children}
            </>
        )
    } else {
        return <Navigate to="/" />
    }
}

export default HasRole;