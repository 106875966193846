import { FormControlLabel, Radio, RadioGroup, SxProps, Theme } from "@mui/material";
import React from "react";
import { useForm } from "./Form";
import { FormFieldValue, Option } from "./type";
import FormFieldWrapper from "./FormFieldWrapper";

type FormFieldProps = {
    variant: "fullWidth" | "long",
    label?: React.ReactNode,
    required?: boolean,
    fieldName: string,
    fieldProps?: object,
    options: Option[],
    sx?: SxProps<Theme>;
}

export const FormFieldRadioGroup = <T extends { [x: string]: FormFieldValue }>(props: FormFieldProps) => {
    const { formData, disabledFields, excludedFields, onFormDataChange } = useForm<T>();

    const { label, required, fieldName, fieldProps } = props;

    const value = formData[fieldName];
    const disabled = disabledFields.includes(fieldName);
    const excluded = excludedFields.includes(fieldName);

    if (excluded) {
        return null;
    }

    const renderField = () => {
        return (
            <RadioGroup
                row
                name={fieldName}
                value={value ?? ""}
                onChange={(event, value) => onFormDataChange(event, { [event.target.name]: value } as Partial<T>)}
            >
                {props.options.map((option) => {
                    if (typeof option === "object") {
                        return (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                label={option.label}
                                disabled={disabled}
                                control={(<Radio required={required} />)}
                                {...option.optionProps}
                                {...fieldProps}
                            />
                        )
                    } else {
                        return (
                            <FormControlLabel
                                key={option}
                                value={option}
                                label={option}
                                disabled={disabled}
                                control={(<Radio required={required} />)}
                                {...fieldProps}
                            />
                        )
                    }
                })}
            </RadioGroup>
        )
    }

    return (
        <FormFieldWrapper
            variant={props.variant}
            label={props.label}
            field={renderField()}
            sx={props.sx}
        />
    )
}

export default FormFieldRadioGroup;