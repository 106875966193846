import { FormDialog, FormDialogCloseEventHandler } from "components/FormDialog/FormDialog";
import { RegisteredClient } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { withToast } from "utils/withToast";
import ClientFormContent from "./ClientFormContent";

type FormData = RegisteredClient & {
    username?: string;
}

type EditClientDialogProps = {
    open: boolean;
    onClose?: FormDialogCloseEventHandler & ((event: FormEvent, reason: "submit_success") => void);
    client?: FormData;
}

const disabledFields: Array<keyof FormData> = ["username"];
const excludedFields: Array<keyof FormData> = ["username"];

export const EditClientDialog = (props: EditClientDialogProps) => {

    const backendService = useBackendService();

    const [formView, setFormView] = useState<FormData>({
        username: "",
        clientName: "",
        clientAuthenticationMethods: [],
        authorizationGrantTypes: [],
        redirectUris: [],
        scopes: [],
        usePKCE: false,
    });

    useEffect(() => {
        if (props.open) {
            const {
                id = "",
                username = "",
                clientName = "",
                clientAuthenticationMethods = [],
                authorizationGrantTypes = [],
                redirectUris = [],
                scopes = [],
                usePKCE = false,
            } = props.client ?? {};
            setFormView({
                id,
                username,
                clientName,
                clientAuthenticationMethods,
                authorizationGrantTypes,
                redirectUris, 
                scopes,
                usePKCE,
            });
        }
    }, [props.client, props.open]);

    const handleFormOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();

        await withToast({
            success: "Updated client",
            error: true,
        }, async () => {
            await backendService.putSystemClients({
                id: formView.id!,
                requestBody: formView,
            });
        });

        props.onClose && props.onClose(event, "submit_success");

        return false;
    }
    
    return (
        <FormDialog
            open={props.open}
            onClose={props.onClose}
            title="Edit client"
            dialogProps={{
                maxWidth: "md",
            }}
            formData={formView}
            onFormDataChange={(event, formData) => setFormView(formData)}
            disabledFields={disabledFields}
            excludedFields={excludedFields}
            onSubmit={handleFormOnSubmit}
        >
            <ClientFormContent />
        </FormDialog>
    )
}

export default EditClientDialog;