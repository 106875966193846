import { Box } from "@mui/material";

type ErrorBoxProps = {
    error?: string;
}

export const ErrorBox = (props: ErrorBoxProps) => {
    if (props.error) {
        return (
            <Box
                sx={{
                    p: 1,
                    mb: 1,
                    color: "#86181d",
                    backgroundColor: "#ffdce0",
                    borderRadius: "8px",
                }}
            >
                {props.error}
            </Box>
        )
    }

    return null;
}