import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { CompanyIcon } from "components/CompanyIcon";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import Form from "components/Form";
import { PasswordFormContent } from "components/PasswordFormContent";
import { nanoid } from "nanoid";
import React, { FormEventHandler, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { validateFormData } from "utils/utils";
import { useTranslation } from "react-i18next";

type FormData = {
    old: string;
    new: string;
    confirm: string;
}

type RenewPasswordPageProps = {

}

export const RenewPasswordPage = (props: RenewPasswordPageProps) => {

    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const [searchParams] = useSearchParams();

    const formIdRef = useRef(nanoid());
    const formId = formIdRef.current;

    const ref_id = useMemo(() => {
        return searchParams.get("ref_id") ?? "";
    }, [searchParams]);

    const isFirstLogin = useMemo(() => {
        const first_login = searchParams.get("first_login");
        return first_login === "true";
    }, [searchParams]);

    const error = useMemo(() => {
        const error = searchParams.get("error");
        if (error !== null) {
            switch (error) {
                case "wrong_password": {
                    return "The old password is not matched";
                }

                case "password_policy_not_meet": {
                    return "Password policy validation failed";
                }

                case "password_repeated": {
                    return "Password has been used in last 5 times";
                }

                case "no_new_password": {
                    return "New password not provided"
                }

                case "invalid_session": {
                    return "Invalid session. Please close this window."
                }

                case "unexpected_error":
                default: {
                    return t("error_msg.unexpected");
                }
            }
        }

        return "";
    }, [searchParams, t]);

    const [formData, setFormData] = useState<FormData>({
        old: "",
        new: "",
        confirm: "",
    });

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleFormOnSubmit: FormEventHandler = (event) => {
        setIsFormSubmitted(true);
    }

    const [formSubmitType, setFormSubmitType] = useState<"skip" | "submit">();

    const isSubmitDisabled = useMemo(() => {
        if (isFormSubmitted) {
            return true;
        }

        if (!validateFormData(formData)) {
            return true;
        }

        if (formData.new !== formData.confirm) {
            return true;
        }

        return false;

    }, [isFormSubmitted, formData]);

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card
                sx={{
                    p: 2,
                    borderRadius: 8,
                }}
            >
                <CardHeader
                    title={(
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Box display="flex" height={50} borderRadius={4}>
                                <CompanyIcon />
                            </Box>
                            <Typography variant="h5" fontWeight={800}>
                                {window.__RUNTIME_ENV__.REACT_APP_ENV !== "TW" && t("deep_Translate")}
                            </Typography>
                        </Stack>
                    )}
                />
                <CardContent>
                    <ErrorBox error={error} />
                    <Form
                        id={formId}
                        formData={formData}
                        onFormDataChange={(event, formData) => setFormData(formData)}
                        onSubmit={handleFormOnSubmit}
                        method="POST"
                        action={process.env.PUBLIC_URL + "/renew-password"}
                    >
                        <Typography variant="h5">
                            {t("renew_password.please_renew")}
                        </Typography>
                        <PasswordFormContent />
                        <input type="hidden" name="ref_id" value={ref_id} />
                        {formSubmitType === "skip" && <input type="hidden" name="skip" value="true" />}
                    </Form>
                </CardContent>
                <CardActions
                    disableSpacing
                    sx={{
                        flexDirection: "row-reverse", // order matters for form submitted by enter
                    }}
                >
                    <LoadingButton
                        type="submit"
                        form={formId}
                        disabled={isSubmitDisabled}
                        loading={isFormSubmitted && formSubmitType === "submit"}
                        onClick={(event) => setFormSubmitType("submit")}
                    >
                        {t("submit")}
                    </LoadingButton>
                    {!isFirstLogin && (
                        <LoadingButton
                            formNoValidate
                            color="secondary"
                            type="submit"
                            form={formId}
                            disabled={isFormSubmitted}
                            loading={isFormSubmitted && formSubmitType === "skip"}
                            onClick={(event) => setFormSubmitType("skip")}
                        >
                            {t("skip")}
                        </LoadingButton>
                    )}
                </CardActions>
            </Card>
        </Box>
    )
}

export default RenewPasswordPage;
