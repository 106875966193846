import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type FormSectionProps = {
    title?: string,
}

export const FormSection = (props: PropsWithChildren<FormSectionProps>) => {
    const { title, children } = props;

    return (
        <Box py={1}>
            <Typography variant="h5">
                {title}
            </Typography>
            <Divider />
            <Box p={1}>
                {children}
            </Box>
        </Box>
    )
}

export default FormSection;