import { Backdrop, Card, CardContent, CardHeader, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { ApiError } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CompanyIcon from "components/CompanyIcon";
import { useTranslation } from "react-i18next";

type Request = {
    status: "pending" | "loading" | "success" | "fail";
    error?: string;
}

const getContentText = (status: "pending" | "loading" | "success" | "fail", t: any) => {
    switch (status) {
        case "loading": {
            return {
                headerText: t("registration.activating"),
            }
        }

        case "success": {
            return {
                headerText: t("registration.activated"),
                contentText: t("registration.check_email"),
            }
        }

        case "fail": {
            return {
                headerText: t("error_msg.error"),
                contentText: t("error_msg.can_not_activate"),
            }
        }

        default: {
            return {};
        }
    }
}

export const ConfirmRegistrationPage = () => {
    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const backendService = useBackendService();

    const [searchParams] = useSearchParams();

    const [request, setRequest] = useState<Request>({
        status: "pending",
    });

    const postRequest = useCallback(async () => {
        const token = searchParams.get("token");
        if (token) {
            setRequest({ status: "loading" });
            try {
                await backendService.postAccountRegistrationConfirm({
                    requestBody: token,
                });
                setRequest({ status: "success" })
            } catch (error) {
                if (error instanceof ApiError) {
                    setRequest({
                        status: "fail",
                        error: error.body,
                    });
                } else {
                    setRequest({
                        status: "fail",
                        error: "Unexpected error",
                    });
                }
            }

        }
    }, [backendService, searchParams]);

    useEffect(() => {
        postRequest();
    }, [postRequest]);

    const contentText = getContentText(request.status, t);

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Card>
                <CardHeader
                    title={contentText.headerText}
                    subheader={request.error}
                    action={
                        <Box display="flex" height={50} borderRadius={4}>
                            <CompanyIcon />
                        </Box>
                    }
                />
                {contentText.contentText && (
                    <CardContent>
                        {contentText.contentText}
                    </CardContent>
                )}
            </Card>
            <Backdrop open={request.status === "loading"}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default ConfirmRegistrationPage;