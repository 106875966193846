import { FormDialog, FormDialogCloseEventHandler } from "components/FormDialog/FormDialog";
import { PasswordFormContent } from "components/PasswordFormContent";
import { ApiError } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import React, { FormEvent, FormEventHandler, useEffect, useMemo, useState } from "react";
import { withToast } from "utils/withToast";

type FormData = {
    old: string;
    new: string;
    confirm: string;
}

const DEFAULT_FORM_DATA: FormData = {
    old: "",
    new: "",
    confirm: "",
}

type ChangePwdDialogProps = {
    open: boolean;
    onClose?: FormDialogCloseEventHandler & ((event: FormEvent, reason: "submit_success") => void)
}

export const ChangePwdDialog = (props: ChangePwdDialogProps) => {

    const backendService = useBackendService();

    const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA);

    useEffect(() => {
        if (!props.open) {
            setFormData(DEFAULT_FORM_DATA)
        }
    }, [props.open]);

    const confirmPasswordValidateText = useMemo(() => {
        if (formData.new !== formData.confirm) {
            return "Your password and confirmation password do not match.";
        }
        return "";
    }, [formData.confirm, formData.new]);

    const submitDisabled = useMemo(() => {
        if (Object.values(formData).some((value) => !value)) {
            return true;
        }
        return !!confirmPasswordValidateText;
    }, [confirmPasswordValidateText, formData]);

    const handleOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();
        await withToast({
            success: "Password changed",
            error: true,
        }, async () => {
            await backendService.postAccountSecurityPassword({
                requestBody: {
                    old: formData.old,
                    new: formData.new,
                }
            });
        });
        props.onClose && props.onClose(event, "submit_success");
    }

    return (
        <FormDialog
            open={props.open}
            onClose={props.onClose}
            title={"Change Password"}
            submitDisabled={submitDisabled}
            formData={formData}
            onFormDataChange={(event, formData) => setFormData(formData)}
            onSubmit={handleOnSubmit}
        >
            <PasswordFormContent />
        </FormDialog>
    )
}

export default ChangePwdDialog;
