import { AuthService, OpenAPI } from "generated/openapi";
import { baseApi } from "./baseApi";
import { resolveServerPath } from "utils/utils";

const publicConfigApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        publicConfig: build.query({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const config = { ...OpenAPI };
                config.BASE = resolveServerPath() + config.BASE;

                const authService = new AuthService(config);

                try {
                    const data = await authService.default.getPublicConfig();
                    return { data }
                } catch (error) {
                    return { error };
                }
            }
        }),
    }),
    overrideExisting: false,
});

export default publicConfigApi;