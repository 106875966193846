import { Button, Card, CardActions, CardContent, CardHeader, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useRef, useState } from "react";
import Form, { FormField } from "../../components/Form";
import { useSearchParams } from "react-router-dom";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import CompanyIcon from "components/CompanyIcon";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";

type FormData = {
    otp_code: string,
}

export const OtpPage = () => {

    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const formIdRef = useRef(nanoid());
    const formId = formIdRef.current;

    const [formData, setFormData] = useState<FormData>({
        otp_code: "",
    });

    const [searchParams] = useSearchParams();

    const ref_id = useMemo(() => {
        return searchParams.get("ref_id") ?? "";
    }, [searchParams]);

    const error = useMemo(() => {
        const error = searchParams.get("error");
        if (error !== null) {
            switch (error) {
                case "invalid_otp": {
                    return t("error_msg.invalid_otp");
                }

                case "invalid_session": {
                    return t("error_msg.invalid_session");
                }

                case "unexpected_error":
                default: {
                    return t("error_msg.unexpected");
                }
            }
        }

        return "";
    }, [searchParams, t]);

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Card
                sx={{
                    width: 480,
                    p: 2,
                    borderRadius: 8,
                }}
            >
                <CardHeader
                    title={(
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Box display="flex" height={50} borderRadius={4}>
                                <CompanyIcon />
                            </Box>
                            <Typography variant="h5" fontWeight={800}>
                                {window.__RUNTIME_ENV__.REACT_APP_ENV !== "TW" && t("deep_Translate")}
                            </Typography>
                        </Stack>
                    )}
                />
                <CardContent>
                    <ErrorBox error={error} />
                    <Form
                        id={formId}
                        formData={formData}
                        onFormDataChange={(event, formData) => setFormData(formData)}
                        method="POST"
                        action={process.env.PUBLIC_URL + "/otp"}
                    >
                        <FormField
                            required
                            variant="fullWidth"
                            label={(
                                <Typography variant="h6">
                                    {t("otp.enter_otp_code")}
                                </Typography>
                            )}
                            fieldName="otp_code"
                            fieldProps={{
                                size: "small",
                                sx: { mb: 1 },
                                inputProps: {
                                    autoComplete: "off",
                                }
                            }}
                        />
                        <input type="hidden" name="ref_id" value={ref_id} />
                    </Form>
                </CardContent>
                <CardActions
                    sx={{
                        flexDirection: "row-reverse",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form={formId}
                        sx={{
                            borderRadius: 4,
                        }}
                    >
                        {t("submit")}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default OtpPage;