/** @jsxImportSource @emotion/react */
import DeepTranslateIcon from "assets/img/icon.png";
import SmartTranslateIcon from "assets/img/icon_tw.png";
import React from "react";

export const CompanyIcon = React.memo(() => {
    return (
        <img
            src={window.__RUNTIME_ENV__.REACT_APP_ENV === "TW" ? SmartTranslateIcon : DeepTranslateIcon}
            css={{
                width: "100%",
                height: "100%",
            }}
        />
    );
});

export default CompanyIcon;