import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

type FormFieldWrapperProps = {
    variant: "short" | "long" | "fullWidth";
    label?: ReactNode;
    field?: ReactNode;
    sx?: SxProps<Theme>;
}

export const FormFieldWrapper = (props: FormFieldWrapperProps) => {
    const renderLabel = () => {
        if (typeof props.label === 'string') {
            return (
                <Typography>
                    {props.label}
                </Typography>
            )
        } else {
            return props.label;
        }
    }

    if (props.variant === "fullWidth") {
        return (
            <Box sx={props.sx} gridColumn={"span 3"}>
                <Box>
                    {renderLabel()}
                </Box>
                <Box>
                    {props.field}
                </Box>
            </Box>
        )
    } else {
        return (
            <>
                <Box sx={props.sx} gridColumn={props.variant === "short" ? "span 2" : "span 1"}>
                    {renderLabel()}
                </Box>
                <Box sx={props.sx} gridColumn={props.variant === "short" ? "span 1" : "span 2"}>
                    {props.field}
                </Box>
            </>
        )
    }
}

export default FormFieldWrapper;