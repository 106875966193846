import PageContainer from "components/PageContainer";
import UserConsole from "./UserConsole";

export const CompanyUserConsolePage = () => {
    return (
        <PageContainer>
            <UserConsole />
        </PageContainer>
    )
}

export default CompanyUserConsolePage;