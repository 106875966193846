import { Box } from "@mui/system";
import ClientConsole from "./ClientConsole";

export const ClientConsolePage = () => {

    
    return (
        <Box sx={{
            height: "100%"
        }}>
            <ClientConsole />
        </Box>
    )
}

export default ClientConsolePage;