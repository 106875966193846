import { Button, Card, CardContent, CardHeader, Divider, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useRef, useState } from "react";
import Form, { FormField } from "../../components/Form";
import { Link, useSearchParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import CompanyIcon from "components/CompanyIcon";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../language/LanguageSelector"
import { Microsoft } from "@mui/icons-material";
import { resolvePublicUrl } from "utils/utils";

const aes = 'ce11df1026ed83cd53fc3c3e8b1fa3c96be838a4263bb59c80960669fca523d0';
const salt = "5d7069bdb4502832"
const pbkdf2Key = CryptoJS.PBKDF2(aes, CryptoJS.enc.Hex.parse(salt), {
    keySize: 256 / 32,
    iterations: 1024
});

const iv = CryptoJS.lib.WordArray.random(256 / 8);
const prefix = "0000000000000000";

type FormData = {
    username: string,
}

const supportSsoMicrosoftLogin = window.__RUNTIME_ENV__.REACT_APP_SUPPORT_SSO_MICROSOFT_LOGIN === "true";
const ssoMicrosoftLoginEntryPoint = window.__RUNTIME_ENV__.REACT_APP_SSO_MICROSOFT_ENTRY_POINT;
const supportFormLogin = window.__RUNTIME_ENV__.REACT_APP_SUPPORT_FORM_LOGIN === "true";
const supportSsoLogin = supportSsoMicrosoftLogin;

export const LoginPage = () => {
    const formIdRef = useRef(nanoid());
    const formId = formIdRef.current;

    const { t } = useTranslation('translation', { keyPrefix: "translation" });

    const [formData, setFormData] = useState<FormData>({
        username: "",
    });

    const passwordInputRef = useRef<HTMLInputElement>(null);

    const [searchParams] = useSearchParams();

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const handleFormOnSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        const passwordInput = passwordInputRef.current!;
        const password = passwordInput.value;
        const saltedPassword = CryptoJS.AES.encrypt(prefix + password, pbkdf2Key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        passwordInput.value = saltedPassword.toString();
        setIsSubmitDisabled(true);
    }

    const error = useMemo(() => {
        const error = searchParams.get("error");
        if (error !== null) {
            switch (error) {
                case "username_not_found": {
                    return t("error_msg.username_not_found");
                }

                case "bad_credentials": {
                    return t("error_msg.incorrect");
                }

                case "account_disabled": {
                    return t("error_msg.disabled");
                }

                case "account_locked": {
                    return t("error_msg.locked");
                }

                case "unexpected_error": {
                    return t("error_msg.unexpected");
                }

                default: {
                    return t("error_msg.failed");
                }
            }
        }

        return "";
    }, [searchParams, t]);

    const renderFormLogin = () => {
        return (
            <Stack
                spacing={2}
                flexGrow={1}
                sx={{
                    minWidth: 400,
                }}
            >
                <Form
                    id={formId}
                    formData={formData}
                    onFormDataChange={(event, formData) => setFormData(formData)}
                    onSubmit={handleFormOnSubmit}
                    method="POST"
                    action={process.env.PUBLIC_URL + "/perform_login"}
                >
                    <FormField
                        required
                        variant="fullWidth"
                        label={t("login.user_name")}
                        fieldName="username"
                    />
                    <FormField
                        variant="fullWidth"
                        label={(
                            <span style={{ whiteSpace: "pre" }}>
                                {t("login.password")}<Link style={{ fontSize: 12 }} to="/forget-password">{t("login.forgot_password")}</Link>
                            </span>
                        )}
                        fieldName="password"
                        fieldComponent={(
                            <TextField
                                required
                                fullWidth
                                type="password"
                                name="password"
                                inputRef={passwordInputRef}
                            />
                        )}
                    />
                </Form>
                <Stack direction="row">
                    <LanguageSelector
                        size="small"
                        sx={{
                            borderRadius: 4,
                        }}
                    />
                    <Box flex={1} />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form={formId}
                        disabled={isSubmitDisabled}
                        sx={{
                            borderRadius: 4,
                        }}
                    >
                        {t("login.sign_in")}
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const renderSSOLogin = () => {
        return (
            <Stack
                spacing={2}
                flexGrow={1}
                justifyContent="center"
            >
                {supportSsoMicrosoftLogin && (
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        startIcon={<Microsoft />}
                        href={resolvePublicUrl(ssoMicrosoftLoginEntryPoint)}
                    >
                        {"Login with Microsoft"}
                    </Button>
                )}
            </Stack>
        )
    }

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Card
                sx={{
                    p: 2,
                    minWidth: 480,
                    borderRadius: 8,
                }}
            >
                <CardHeader
                    title={(
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Box display="flex" height={50} borderRadius={4}>
                                <CompanyIcon />
                            </Box>
                            <Typography variant="h5" fontWeight={800}>
                                {window.__RUNTIME_ENV__.REACT_APP_ENV !== "TW" && t("deep_Translate")}
                            </Typography>
                        </Stack>
                    )}
                />
                <CardContent>
                    <Stack spacing={4}>
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={800}
                                lineHeight={1.2}
                            >
                                {t("login.sign_in")}
                            </Typography>
                            <Typography
                                variant="body1"
                                lineHeight={1}
                            >
                                {t("login.continue")} {window.__RUNTIME_ENV__.REACT_APP_ENV === "TW" ? t("smart_Translate") : t("deep_Translate")}
                            </Typography>
                        </Box>
                        <ErrorBox error={error} />
                        {supportFormLogin && renderFormLogin()}
                        {supportFormLogin && supportSsoLogin && (
                            <Divider flexItem>
                                <Typography color="textSecondary">
                                    {"or"}
                                </Typography>
                            </Divider>
                        )}
                        {supportSsoLogin && renderSSOLogin()}
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}

export default LoginPage;