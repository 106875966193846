import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import Form from "components/Form";
import { FormDialogCloseEventHandler } from "components/FormDialog/FormDialog";
import { ApiError, RegisteredClientCredentials, RegisteredClientPayload } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import React, { FormEventHandler, useState } from "react";
import { toast } from "react-toastify";
import ClientFormContent from "./ClientFormContent";

type CreateClientDialogProps = {
    open: boolean;
    onClose: FormDialogCloseEventHandler & ((event: React.MouseEvent, reason: "submit_success") => void);
}

const initialFormData: RegisteredClientPayload & { username: string } = {
    username: "",
    clientName: "",
    clientAuthenticationMethods: [],
    authorizationGrantTypes: [],
    redirectUris: [],
    scopes: [],
};

export const CreateClientDialog = (props: CreateClientDialogProps) => {
    const backendService = useBackendService();

    const [formData, setFormData] = useState(initialFormData);

    const [clientCredentials, setClientCredentials] = useState<RegisteredClientCredentials | null>(null);

    const handleFormOnSubmit: FormEventHandler = async (event) => {
        event.preventDefault();

        try {
            const response = await backendService.postSystemClients({
                requestBody: formData,
            });
            setClientCredentials(response);
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error(error.message);
            }
        }

        return false;
    }

    const renderContent = () => {
        if (!clientCredentials) {
            return (
                <>
                    <DialogContent>
                        <Form
                            id="create-client-form"
                            formData={formData}
                            onFormDataChange={(event, formData) => setFormData(formData)}
                            onSubmit={handleFormOnSubmit}
                        >
                            <ClientFormContent />
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => props.onClose(event, "cancelled")}>
                            {"Cancel"}
                        </Button>
                        <Button type="submit" form="create-client-form">
                            {"Submit"}
                        </Button>
                    </DialogActions>
                </>
            )
        } else {
            return (
                <>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            {"Client created with following credentials:"}
                        </Typography>
                        <Typography variant="body2" my={2}>
                            {"client_id: "}{clientCredentials.clientId}<br />
                            {"client_secret: "}{clientCredentials.clientSecret}<br />
                        </Typography>
                        <Typography variant="body2">
                            {"Client secret will be shown only once, please keep it save"}
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" onClick={(event) => props.onClose(event, "submit_success")}>
                            {"Confirm"}
                        </Button>
                    </DialogActions>
                </>
            )
        }
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            TransitionProps={{
                onExited: () => {
                    setFormData(initialFormData);
                    setClientCredentials(null);
                }
            }}
        >
            {renderContent()}
        </Dialog>
    )
}