import { ApiError } from "generated/openapi";
import { toast } from "react-toastify";

type WithToastOptions = {
    success?: string | boolean;
    error?: string | boolean;
}

export const withToast = async <T>(options: WithToastOptions, callback: () => Promise<T>) => {
    try {
        const result = await callback();
        if (options.success) {
            if (typeof options.success === "string") {
                toast.success(options.success);
            } else {
                toast.success(result as any);
            }
        }
        return result;

    } catch (error) {
        if (error instanceof ApiError) {
            if (options.error) {
                if (typeof options.error === "string") {
                    toast.error(options.error);
                } else {
                    toast.error(error.body);
                }
            }
        }

        throw error;
    }
}