import { GppGood, LockOpen } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import ConfirmDialog from "components/ConfirmDialog";
import { FormDialogCloseReason } from "components/FormDialog/FormDialog";
import { ApiError } from "generated/openapi";
import { useBackendService } from "providers/BackendService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAccountSecurity } from "../AccountPage";
import ActivateOtpDialog from "./ActivateOtpDialog";

export const TwoFactorAuthenticationCard = () => {

    const backendService = useBackendService();

    const [openedDialog, setOpenedDialog] = useState<"" | "confirm-deactivate-dialog" | "activate-dialog">("");

    const {
        accountSecurity: {
            otp
        },
        refetch
    } = useAccountSecurity();

    const handleDialogOnClose = async (event: any, reason?: FormDialogCloseReason | "submit_success") => {
        if (reason === "submit_success") {
            await refetch();
        }
        setOpenedDialog("");
    }

    const renderStatus = () => {
        if (otp?.enabled) {
            return (
                <Stack direction="row" spacing={2}>
                    <GppGood color="success" />
                    <Typography>
                        {"Activated"}
                    </Typography>
                </Stack>
            )
        } else {
            return (
                <Stack direction="row" spacing={2}>
                    <LockOpen />
                    <Typography>
                        {"Deactivated"}
                    </Typography>
                </Stack>
            )
        }
    }

    const handleDeactivateOnConfirm = async () => {
        try {
            await backendService.postAccountSecurityOtpDeactivate();
            await refetch();
            setOpenedDialog("");
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error(error.message);
            }
        }
    }

    const renderActions = () => {
        if (otp?.enabled) {
            return (
                <Button onClick={() => setOpenedDialog("confirm-deactivate-dialog")}>
                    {"Deactivate"}
                </Button>
            )
        } else {
            return (
                <Button onClick={() => setOpenedDialog("activate-dialog")}>
                    {"Activate"}
                </Button>
            )
        }
    }

    return (
        <>
            <Card variant="outlined">
                <CardHeader
                    title={"Two-Factor Authentication"}
                />
                <CardContent>
                    <Grid2 container>
                        <Grid2 xs={2}>
                            <Typography>
                                {"Status"}
                            </Typography>
                        </Grid2>
                        <Grid2 xs>
                            {renderStatus()}
                        </Grid2>
                    </Grid2>
                </CardContent>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {"You can activate Two-Factor Authentication to further protect you account."}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "flex-end"
                    }}
                >
                    {renderActions()}
                </CardActions>
            </Card>
            <ConfirmDialog
                open={openedDialog === "confirm-deactivate-dialog"}
                onClose={handleDialogOnClose}
                type="error"
                title={"Confirm?"}
                content={"Are you sure to deactive Two-Factor Authentication for you account?"}
                onConfirm={handleDeactivateOnConfirm}
            />
            <ActivateOtpDialog
                open={openedDialog === "activate-dialog"}
                onClose={handleDialogOnClose}
            />
        </>
    )
}

export default TwoFactorAuthenticationCard;