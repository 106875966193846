import { Typography } from "@mui/material";
import { FormField, useForm } from "components/Form";
import { useMemo } from "react";
import publicConfigApi from "store/apis/publicConfig";
import { useTranslation } from "react-i18next";

type FormData = {
    old: string;
    new: string;
    confirm: string;
}

type PasswordFormContentProps = {

}

export const PasswordFormContent = () => {

    const { t } = useTranslation('translation', {keyPrefix: "translation"});

    const { formData } = useForm<FormData>();

    const confirmValidateText = useMemo(() => {
        if (formData.new !== formData.confirm) {
            return t("error_msg.pwd_not_match");
        }
        return "";
    }, [formData.confirm, formData.new, t]);

    // select in option to rename
    const { isLoading, configCount } = publicConfigApi.usePublicConfigQuery(undefined, {
        selectFromResult: (state) => {
            const { data, ...otherState } = state;
            const configCount = state.data?.noPasswordRepeatIn;
            return {
                configCount,
                ...otherState,
            }
        }
    });

    if (isLoading) {
        return null;
    }

    return (
        <>
            {configCount && configCount > 0 && (
                <Typography>
                    {`The password must not be used in previous ${configCount} time(s)`}<br />
                </Typography>
            )}
            <Typography>
                {t("renew_password.must_contain")}<br />
            </Typography>
            <Typography
                color="text.secondary"
                component="ol"
                sx={{
                    my: 2
                }}
            >
                <li>{t("renew_password.contain_uppercase")}</li>
                <li>{t("renew_password.contain_lowercase")}</li>
                <li>{t("renew_password.contain_numberic")}</li>
                <li>{t("renew_password.contain_special")}</li>
            </Typography>

            <FormField
                required
                variant="fullWidth"
                label={t("renew_password.current_pwd")}
                fieldName="old"
                fieldType="password"
            />
            <FormField
                required
                variant="fullWidth"
                label={t("renew_password.new_pwd")}
                fieldName="new"
                fieldType="password"
            />
            <FormField
                required
                variant="fullWidth"
                label={t("renew_password.confirm_pwd")}
                fieldName="confirm"
                fieldType="password"
                fieldProps={{
                    error: !!confirmValidateText,
                    helperText: confirmValidateText,
                }}
            />
        </>
    )

}

export default PasswordFormContent;