import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAccountSecurity } from "../AccountPage";
import * as DateFns from "date-fns";
import { useState } from "react";
import ChangePwdDialog from "./ChangePwdDialog";
import { FormDialogCloseReason } from "components/FormDialog/FormDialog";

const getValidDate = (timestamp?: string | number | Date | null) => {
    if (!timestamp) {
        return null;
    }

    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
        return null;
    }

    return date;
}

const displayDate = (timestamp?: string | number | Date | null) => {
    const date = getValidDate(timestamp);
    return date ? DateFns.format(date, "yyyy-MM-dd HH:mm") : "";
}

const displayLastEdit = (timestamp?: string | number | Date | null) => {
    const date = getValidDate(timestamp);
    if (date) {
        const now = new Date();
        const timeDiff = now.getTime() - date.getTime();
        const dayDiff = timeDiff / 86400000;

        if (dayDiff < 1) {
            return `${displayDate(timestamp)} (within 1 day)`;
        } else {
            return `${displayDate(timestamp)} (${Math.floor(dayDiff)} day(s) ago)`;
        }
    } else {
        return "";
    }
}

export const PwdCard = () => {

    const {
        accountSecurity: {
            password,
        },
        refetch
    } = useAccountSecurity();

    const [openedDialog, setOpenedDialog] = useState<"" | "change-password-dialog">("");

    const handleDialogOnClose = async (event: any, reason: FormDialogCloseReason | "submit_success") => {
        if (reason === "submit_success") {
            await refetch();
        }
        setOpenedDialog("");
    }

    return (
        <>
            <Card variant="outlined">
                <CardHeader
                    title={"Password"}
                />
                <CardContent>
                    <Grid2 container>
                        <Grid2 xs={2}>
                            {"Last modified"}
                        </Grid2>
                        <Grid2 xs>
                            {displayLastEdit(password?.lastEdit)}
                        </Grid2>
                    </Grid2>
                </CardContent>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {"We suggest you to change you password every 90 days."}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "flex-end"
                    }}
                >
                    <Button onClick={() => setOpenedDialog("change-password-dialog")}>
                        {"Change password"}
                    </Button>
                </CardActions>
            </Card>
            <ChangePwdDialog
                open={openedDialog === "change-password-dialog"}
                onClose={handleDialogOnClose}
            />
        </>
    )
}

export default PwdCard;